import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  IBACnetMBusMappingConnectionProp, IBACnetMBusMappingPasteConf,
  IBACnetMBusMappingRecord,
  IBACnetMBusMappingRecordNew,
  IMBusMethod, ISlaveChangeAddress,
  ISlaveChangeId
} from "../core/interfaces/mapping";
import {MappingService} from "../core/services/mapping.service";
import {Form, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IBACnetObjectTypeAbbreviation} from "../core/interfaces/bacnet";
import {WebsocketService} from "../core/services/websocket.service";
import {TerminalService} from "primeng/terminal";
import {MbusNetworkService} from "../core/services/mbus-network.service";
import {LoggerService} from "../core/services/logger.service";
import {EError, EMBusConfState} from "../core/interfaces/app-enum";
import {StateService} from "../core/services/state.service";
import {ISearchTask, ISearchTaskQuery} from "../core/interfaces/task";
import {TaskService} from "../core/services/task.service";
import {EMBusMethod} from "../core/interfaces/mbus";
import {ToastService} from "../core/services/toast.service";
import {Router} from "@angular/router";
import {MegaMenuItem, MenuItem} from "primeng/api";
import {catchError, concatMap, EMPTY, from} from "rxjs";
import saveAs from 'file-saver';
import {MbusSlaveService} from "../core/services/mbus-slave.service";
import {BlockUI, NgBlockUI} from "ng-block-ui";

export const atLeastOneHasValue = (fields: Array<string>) => {
  return (group: FormGroup) => {
    for (const fieldName of fields) {
      if (group.get(fieldName)?.value) {
        return null;
      }
    }
    return { oneAddressRequired: true };
  };
};


@Component({
  selector: 'app-meter-list',
  templateUrl: './meter-list.component.html',
  styleUrls: ['./meter-list.component.scss']
})
export class MeterListComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI: NgBlockUI;
  menuItems: MegaMenuItem[] = [];

  // private subscription: Subscription;
  public message: string = "";

  cols: any[] = [];
  private state: EMBusConfState = EMBusConfState.IDLE;
  searchMetersLabel: string = "Search meters";
  searchMetersDialogVisible: boolean = false;
  changeIDDialogVisible: boolean = false;
  changeAddressDialogVisible: boolean = false;
  changeBaudRateDialogVisible: boolean = false;
  connPropDialogVisible: boolean = false;
  items: MenuItem[] = [];
  constructor(private router: Router, private taskService: TaskService, private slaveService: MbusSlaveService, private mappingService: MappingService, private fb: FormBuilder, private websocketService: WebsocketService, private terminalService: TerminalService, private mbusNetworkService: MbusNetworkService, private loggerService: LoggerService, private stateService: StateService, private toastService: ToastService) {
    websocketService.messages.subscribe(msg => {
      // this.received.push(msg);
      if (msg.data.topic == "log" || msg.data.topic == "meter-search-result") {
        this.loggerService.addMessage(msg.data.message);
        if (Object.keys(msg.data.value).length > 0) {
          this.mbusMappings.push(msg.data.value);
        }
      } else if (msg.data.topic == "state") {
        console.log("STAN");
        this.state = msg.data.data.state;
        this.onStateChange();
      }
    });
  }

  blockUIStart() {
    this.blockUI.start('Loading...');
  }

  blockUIStop() {
    this.blockUI.stop();
  }

  mbusMappings: IBACnetMBusMappingRecord[] = [];
  selectedMbusMappings: IBACnetMBusMappingRecord[] = [];
  mappingsToPaste: IBACnetMBusMappingRecord[] = [];
  mappingsToEditConnnection: IBACnetMBusMappingRecord[] = [];
  copiedMapping: IBACnetMBusMappingRecord | undefined;
  contextItems: MenuItem[] = [];
  selectedFromContext: IBACnetMBusMappingRecord = {} as IBACnetMBusMappingRecord;

  IMBusMethod = IMBusMethod;
  IBACnetObjectTypeAbbreviation = IBACnetObjectTypeAbbreviation;
  newMeterDialogVisible: boolean = false;
  pasteRecordsDialogVisible: boolean = false;
  newMeterForm!: FormGroup;
  searchMetersForm!: FormGroup;
  changeIDForm!: FormGroup;
  changeAddressForm!: FormGroup;
  changeBaudRateForm!: FormGroup;
  pasteRecordsForm!: FormGroup;
  connPropForm!: FormGroup;
  baudRates: number[] = [300,2400,9600,38400];
  // blockedDocument: boolean = false;

  ngOnInit(): void {
    this.cols = [
      {field: 'meterID', header: 'Meter ID'},
      {field: 'address', header: 'Addr'},
      {field: 'baudRate', header: 'Baud rate'},
      {field: 'method', header: 'Method'},
      {field: 'nke', header: 'NKE'},
      {field: 'rst', header: 'RST'},
      {field: 'model', header: 'Model'},
      {field: 'status', header: 'Status'},
      {field: 'object', header: 'Object'},
      {field: 'name', header: 'Name'},
      {field: 'description', header: 'Description'},
      {field: 'interval', header: 'Interval'},
      {field: 'records', header: 'Records'},
      {field: 'status', header: 'Status'},
    ]

    this.contextItems = [
      {
        label: "Delete",
        icon: "pi pi-trash",
        command: () => {
          let tmpArray: IBACnetMBusMappingRecord[] = [];
          tmpArray.push(this.selectedFromContext);
          this.deleteMeter(tmpArray);
        }
      },
      {
        separator:true
      },
      {
        label: "Copy",
        icon: "pi pi-clone",
        command: () => {
          this.copiedMapping = this.selectedFromContext;
          this.toastService.success("Record copied");
        }
      },
      {
        label: "Paste",
        icon: "pi pi-book",
        command: () => {
          let tmpArray: IBACnetMBusMappingRecord[] = [];
          tmpArray.push(this.selectedFromContext);
          this.mappingsToPaste = tmpArray;
          this.pasteRecordsDialogVisible = true;
        }
      },
      {
        separator:true
      },
      {
        label: "Data records",
        icon: "pi pi-list",
        command: () => {
          this.router.navigate(["/meter-data"], {queryParams: {id: this.selectedFromContext.i, method: this.selectedFromContext.me, address: this.selectedFromContext.me == IMBusMethod.PRI ? this.selectedFromContext.a : this.selectedFromContext.i}});
        }
      },
      {
        label: "Edit connection parameters",
        icon: "pi pi-pencil",
        command: () => {
          let tmpArray: IBACnetMBusMappingRecord[] = [];
          tmpArray.push(this.selectedFromContext);
          this.mappingsToEditConnnection = tmpArray;
          if (this.mappingsToEditConnnection.filter(e => e.bacnet_interval).length == 0) {
            this.connPropForm.get("connInterval")?.removeValidators([Validators.required, Validators.min(0),Validators.max(1440)]);
          } else {
            this.connPropForm.get("connInterval")?.addValidators([Validators.required, Validators.min(0),Validators.max(1440)]);
          }
          this.openConnectionPropDialog();
        }
      },
      {
        separator:true
      },
      {
        label: "Create BACnet object",
        command: () => {
          let tmpArray: IBACnetMBusMappingRecord[] = [];
          tmpArray.push(this.selectedFromContext);
          this.createBACnetObject(tmpArray);
        }
      },
      {
        label: "Delete BACnet object",
        command: () => {
          let tmpArray: IBACnetMBusMappingRecord[] = [];
          tmpArray.push(this.selectedFromContext);
          this.deleteBACnetObject(tmpArray);
        }
      },
      {
        separator:true
      },
      {
        label: "Test slave"
      },
      {
        label: "Set ID"
      },
      {
        label: "Set Address"
      },
      {
        label: "Set baud rate"
      },
    ]

    // this.blockedDocument = true;
    this.items = [
      {
        label:'BACnet',
        items: [
          {
            label: 'Create BACnet object',
            command: (event) => {
              this.createBACnetObject(this.selectedMbusMappings);
            },
            disabled: !this.isMultipleSelection()
          },
          {
            label: 'Delete BACnet object',
            command: (event) => {
              this.deleteBACnetObject(this.selectedMbusMappings);
            },
            disabled: !this.isMultipleSelection()
          },
          {
            label: 'Name - Find and replace',
            command: (event) => {
              this.openNewMeterDialog();
            },
            disabled: true
          },
          {
            label: 'Restore default names',
            command: (event) => {
              this.openNewMeterDialog();
            },
            disabled: true
          }
        ]
      }, {
        label:'M-Bus',
        items: [
          {
            label: 'Search new slaves',
            command: (event) => {
              this.searchMeters();
            }
          },
          {
            label: 'Test slave',
            command: (event) => {
              this.askForAddressAndModel(this.selectedMbusMappings);
            },
            disabled: !this.isMultipleSelection()
          },
          {
            label: 'Set Address',
            command: (event) => {
              this.openChangeAddressDialog();
            },
            disabled: !this.isSingleSelection()
          },
          {
            label: 'Set ID',
            command: (event) => {
              this.openChangeIDDialog();
            },
            disabled: !this.isSingleSelection()
          },
          {
            label: 'Set baud rate',
            command: (event) => {
              this.openChangeBaudRateDialog();
            },
            disabled: !this.isSingleSelection()
          }
        ]
      },
      // {
      //   label: 'Stop searching',
      //   icon: 'pi pi-stop-circle',
      //   styleClass: 'p-button-danger',
      //   visible: this.isSearchMode()
      // }
    ];
    this.stateService.getState().subscribe(e => {
      this.state = e.state;
      this.onStateChange();
    })

    this.refreshMappings();

    this.newMeterForm = this.fb.group({
      newMeterId: '',
      newMeterAddress: '',
      newMeterBaudRate: 2400,
      newMeterMethod: IMBusMethod.PRI,
      newMeterNKE: false,
      newMeterRST: true
    }, {
      validators: atLeastOneHasValue(['newMeterId'])
    })
    this.initializeSearchMetersForm();
    this.initializieChangeIDForm();
    this.initializieChangeAddressForm();
    this.initializieChangeBaudRateForm();
    this.initializiePasteForm();
    this.initializieConnPropForm();
  }

  refreshMappings() {
    this.lockUI();
    this.mappingService.getBacnetMBusMappingRecords().subscribe(e => {
      this.mbusMappings = e;
      this.unlockUI();
    });
  }

  initializieConnPropForm() {
    this.connPropForm = this.fb.group({
      connMethod: IMBusMethod.PRI,
      connBaudRate: 2400,
      connNKE: false,
      connRST: false,
      connInterval: [1, [Validators.required, Validators.min(1),Validators.max(1440)]]
    })
  }

  private initializiePasteForm() {
    this.pasteRecordsForm = this.fb.group({
      description: false,
      baudRate: false,
      nke: false,
      rst: false,
      interval: false,
      records: false
    });
  }
  private initializieChangeIDForm() {
    this.changeIDForm = this.fb.group({
      changeIDMethod: IMBusMethod.PRI,
      changeIDNewID: [null, Validators.required]
    })
  }

  private initializieChangeAddressForm() {
    this.changeAddressForm = this.fb.group({
      changeAddressMethod: IMBusMethod.PRI,
      changeAddressNewAddress: [null, Validators.required]
    })
  }

  private initializieChangeBaudRateForm() {
    this.changeBaudRateForm = this.fb.group({
      changeBaudRateMethod: IMBusMethod.PRI,
      changeBaudRateNewBaudRate: 2400
    })
  }

  private initializeSearchMetersForm() {
    this.searchMetersForm = this.fb.group({
      erase: false,
      method: IMBusMethod.PRI,
      minRange: [0, [Validators.required, Validators.min(0),Validators.max(250)]] ,
      maxRange: [250, [Validators.required, Validators.min(0),Validators.max(250)]],
      baudRate: [2400, [Validators.required]],
    });
    this.searchMetersForm.get("method")?.valueChanges.subscribe(e => {
      this.onMethodChange(e);
    })
  }

  getExportObject() {
    return this.mbusMappings.map(m => {
      return {
        'Meter ID': m.i,
        'Addr': m.a,
        'Baud rate': m.b,
        'Method': IMBusMethod[m.me],
        'NKE': m.n ? 'X' : 'V',
        'RST': m.r ? 'X' : 'V',
        'Model': m.m,
        'Status': m.s ? 'X' : 'V',
        'Object': IBACnetObjectTypeAbbreviation[Number(m.bacnet_object_type)] + m.bacnet_instance,
        'Name': m.bacnet_name,
        'Description': m.bacnet_description,
        'Interval [min]': m.bacnet_interval,
        'Records': m.bacnet_records
      }
    })
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.getExportObject());
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "meters");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }


  onChangeIDSubmit(record: IBACnetMBusMappingRecord) {
    this.lockUI();
    let changeID: ISlaveChangeId = {} as ISlaveChangeId;
    changeID.mbus_method = this.selectedMbusMappings[0].me;
    changeID.new_id = this.changeIDForm.get('changeIDNewID')?.value;
    changeID.mapping_id = this.selectedMbusMappings[0].i;
    changeID.req_addr = this.selectedMbusMappings[0].me == IMBusMethod.PRI ? record.a : record.i;
    this.slaveService.changeId(changeID).subscribe({
      next: (e) => {
        let tmp;
        tmp = this.mbusMappings.find(r => r.i == record.i);
        if (tmp) {
          let index = this.mbusMappings.indexOf(tmp);
          this.mbusMappings[index] = e;
        }
        this.updateTableRow(e);
        this.toastService.success("Slave ID changed, record updated");
        this.hideChangeIDDialog();
        this.unlockUI();
      },
      error: (err) => {
        this.toastService.error(EError[err.error.error]);
        this.unlockUI();
      },
      complete: () => {
        this.clearSelection();
      },
    });
  }

  onChangeAddressSubmit(record: IBACnetMBusMappingRecord) {
    this.lockUI();
    let changeAddress: ISlaveChangeAddress = {} as ISlaveChangeAddress;
    changeAddress.mbus_method = this.selectedMbusMappings[0].me;
    changeAddress.new_address = this.changeAddressForm.get('changeAddressNewAddress')?.value;
    changeAddress.mapping_id = this.selectedMbusMappings[0].i;
    changeAddress.req_addr = this.selectedMbusMappings[0].me == IMBusMethod.PRI ? record.a : record.i;
    this.slaveService.changeAddress(changeAddress).subscribe({
      next: (e) => {
        let tmp;
        tmp = this.mbusMappings.find(r => r.i == record.i);
        if (tmp) {
          let index = this.mbusMappings.indexOf(tmp);
          this.mbusMappings[index] = e;
        }
        // this.updateTableRow(e);
        this.toastService.success("Slave Address changed, record updated");
        this.hideChangeAddressDialog();
        this.unlockUI();
      },
      error: (err) => {
        this.toastService.error(EError[err.error.error]);
        this.unlockUI();
      },
      complete: () => {
        this.clearSelection();
      },
    });
  }
  onChangeBaudRateSubmit() {

  }


  onSearchMetersSubmit() {
    let searchTask: ISearchTask = {} as ISearchTask;
    searchTask.id = 1; //TODO should be random generated
    searchTask.method = "search";
    searchTask.params = {} as ISearchTaskQuery;
    searchTask.params.method = this.searchMetersForm.get('method')?.value;
    searchTask.params.baudRate = this.searchMetersForm.get('baudRate')?.value;
    searchTask.params.minAddress = this.searchMetersForm.get('minRange')?.value;
    searchTask.params.maxAddress = this.searchMetersForm.get('maxRange')?.value;
    searchTask.params.erase = this.searchMetersForm.get('erase')?.value;

    this.taskService.createSearchTask(searchTask).subscribe(
      value => {
        if (this.searchMetersForm.get('erase')?.value) {
          this.mbusMappings = [];
          this.mbusMappings = [...this.mbusMappings];
        }
        // this.toastService.success("Search process started");
        this.searchMetersDialogVisible = false;
        this.state = EMBusConfState.MBUS_SEARCH;
        this.onStateChange();
      }
    )
  }

  hideSearchMetersDialog() {
    this.searchMetersDialogVisible = false;
  }

  hideChangeIDDialog() {
    this.changeIDDialogVisible = false;
  }

  hideChangeAddressDialog() {
    this.changeAddressDialogVisible = false;
  }

  hideChangeBaudRateDialog() {
    this.changeBaudRateDialogVisible = false;
  }

  hideConnPropDialog() {
    this.connPropDialogVisible = false;
  }

  hidePasteDialog() {
    this.pasteRecordsDialogVisible = false;
  }

  onMethodChange(method: EMBusMethod) {
    if (EMBusMethod.PRI == method) {
      this.searchMetersForm.get("minRange")?.setValidators([Validators.required, Validators.min(0),Validators.max(250)]);
      this.searchMetersForm.get("maxRange")?.setValidators([Validators.required, Validators.min(0),Validators.max(250)]);
    } else {
      this.searchMetersForm.get("minRange")?.clearValidators();
      this.searchMetersForm.get("minRange")?.setErrors(null);
      this.searchMetersForm.get("maxRange")?.clearValidators();
      this.searchMetersForm.get("maxRange")?.setErrors(null);
    }
    this.searchMetersForm.updateValueAndValidity();
  }

  private onStateChange() {
    switch (this.state) {
      case EMBusConfState.IDLE:
        this.onRowSelect();
        this.searchMetersLabel = "Search meters"
        if (this.items[2]) {
          this.items[2].visible = false;
        }
        break;
      case EMBusConfState.MBUS_SEARCH:
        this.onRowSelect();
        this.searchMetersLabel = "Stop search";
        if (this.items[2]) {
          this.items[2].visible = true;
        }
        break;
    }
  }


  editConnProp() {
    this.mappingsToEditConnnection = this.selectedMbusMappings;
    if (this.mappingsToEditConnnection.filter(e => e.bacnet_interval).length == 0) {
      this.connPropForm.get("connInterval")?.removeValidators([Validators.required, Validators.min(0),Validators.max(1440)]);
    } else {
      this.connPropForm.get("connInterval")?.addValidators([Validators.required, Validators.min(0),Validators.max(1440)]);
    }
    this.openConnectionPropDialog();
  }

  openConnectionPropDialog() {
    this.connPropForm.patchValue({
      connMethod: this.mappingsToEditConnnection[0].me,
      connBaudRate: this.mappingsToEditConnnection[0].b,
      connNKE: this.mappingsToEditConnnection[0].n,
      connRST: this.mappingsToEditConnnection[0].r,
      connInterval: this.mappingsToEditConnnection[0].bacnet_interval
    });
    this.connPropDialogVisible = true;
  }

  onNewMeterSubmit() {
    if (this.newMeterForm.valid) {
      let newMeterDTO = {} as IBACnetMBusMappingRecordNew;
      newMeterDTO.mbus_id = Number(this.newMeterForm.get("newMeterId")?.value);
      newMeterDTO.mbus_addr = Number(this.newMeterForm.get("newMeterAddress")?.value);
      newMeterDTO.mbus_baud_rate = this.newMeterForm.get("newMeterBaudRate")?.value;
      newMeterDTO.mbus_method = this.newMeterForm.get("newMeterMethod")?.value;
      newMeterDTO.mbus_nke = this.newMeterForm.get("newMeterNKE")?.value ? 1 : 0;
      newMeterDTO.mbus_rst = this.newMeterForm.get("newMeterRST")?.value ? 1 : 0;
      this.mappingService.addBacnetMBusMappingRecord(newMeterDTO).subscribe({
        next: (e) => {
          this.mbusMappings.push(e);
          this.toastService.success("MBUS meter added");
          this.hideNewMeterDialog();
        },
        error: (err) => {
          this.toastService.error(EError[err.error.error]);
        },
        complete: () => {},
      });
    }
  }

  hideNewMeterDialog() {
    this.newMeterDialogVisible = false;
  }

  openNewMeterDialog() {
    this.newMeterDialogVisible = true;
  }

  openChangeIDDialog() {
    this.changeIDDialogVisible = true;
  }

  openChangeAddressDialog() {
    this.changeAddressDialogVisible = true;
  }

  openChangeBaudRateDialog() {
    this.changeBaudRateDialogVisible = true;
  }

  createBacnetObjectDisabled() {
    return this.selectedMbusMappings.length != 1 || 'bacnet_instance' in this.selectedMbusMappings[0];
  }

  updateTableRow(mapping: IBACnetMBusMappingRecord) {
    let tmp;
    tmp = this.mbusMappings.find(r => r.i == mapping.i);
    if (tmp) {
      let index = this.mbusMappings.indexOf(tmp);
      this.mbusMappings[index] = mapping;
    }
  }

  updateSelectedTableRow(mapping: IBACnetMBusMappingRecord) {
    let index = this.mbusMappings.indexOf(this.selectedMbusMappings[0]);
    this.mbusMappings[index] = mapping;
  }

  clearSelection() {
    this.selectedMbusMappings = [];
    this.onRowSelect();
  }

  deleteBacnetObjectDisabled() {
    return !(this.selectedMbusMappings.length == 1 && 'bacnet_instance' in this.selectedMbusMappings[0]);
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }

  searchMeters() {
    switch (this.state) {
      case EMBusConfState.IDLE:
        // this.mbusNetworkService.startSearchSlave().subscribe(e => {
        //   console.log("START SEARCH");
        // })
        this.searchMetersDialogVisible = true;
        break;
      case EMBusConfState.MBUS_SEARCH:
        // this.mbusNetworkService.stopSearchSlave().subscribe(e => {
        //   console.log("STOP SEARCH");
        // })
        this.stopSearch();
    }
  }
  stopSearch() {
    // this.meterFoundSubscription.unsubscribe();
    this.taskService.deleteSearchTask().subscribe(e => {
      // this.toastService.success("Searching slaves stopped");
      this.state = EMBusConfState.IDLE;
      this.onStateChange();
    })
  }
  stopSearchMBusMeters() {
    this.mbusNetworkService.stopSearchSlave().subscribe(e => {
      console.log("STOP SEARCH");
    })
  }

  isSearchMode() {
    return EMBusConfState.MBUS_SEARCH == this.state;
  }

  isFreeMode() {
    return EMBusConfState.IDLE == this.state;
  }

  askForAddressAndModel(records: IBACnetMBusMappingRecord[]) {
    this.lockUI();
    from(records).pipe(
      concatMap(ev => {
        return this.mappingService.testSlave(ev.a, ev.i, ev.me).pipe(catchError(err => {
          this.toastService.error(EError[err.error.error]);
          return EMPTY;
          }
        ))
      })
    ).subscribe({
      next: (e) => {
          if (e) {
            this.toastService.success("Slave found - mapping record updated");
            this.updateSelectedTableRow(e);
          }
      },
      error: (err) => {
        this.toastService.error(EError[err.error.error]);
        this.unlockUI();
      },
      complete: () => {
        this.unlockUI();
        this.clearSelection();
      }
    })
  }

  askForAddressAndModelDisabled(): boolean {
    return this.selectedMbusMappings.length != 1;
  }

  copy() {
    this.copiedMapping = this.selectedMbusMappings[0];
    this.selectedMbusMappings = [];
    this.toastService.success("Record copied");
  }

  paste() {
    this.mappingsToPaste = this.selectedMbusMappings;
    this.pasteRecordsDialogVisible = true;
  }


  edit() {
    this.router.navigate(["/meter-data"], {queryParams: {id: this.selectedMbusMappings[0].i, method: this.selectedMbusMappings[0].me, address: this.selectedMbusMappings[0].me == IMBusMethod.PRI ? this.selectedMbusMappings[0].a : this.selectedMbusMappings[0].i}});
  }

  editDisabled() :boolean {
    return this.selectedMbusMappings.length != 1;
  }

  isSingleSelection(): boolean {
    return this.selectedMbusMappings.length == 1;
  }

  isMultipleSelection(): boolean {
    return this.selectedMbusMappings.length > 0;
  }


  onRowSelect() {
    if (this.items[0] && this.items[0].items) {
        this.items[0].items[0].disabled = !this.isMultipleSelection() || this.isSearchMode();
        this.items[0].items[1].disabled = !this.isMultipleSelection() || this.isSearchMode();
        // this.items[0].items[2].disabled = !this.isMultipleSelection() || this.isSearchMode();
        // this.items[0].items[3].disabled = !this.isMultipleSelection() || this.isSearchMode();
    }

    if (this.items[1] && this.items[1].items) {
      this.items[1].items[1].disabled = !this.isMultipleSelection() || this.isSearchMode();
      this.items[1].items[2].disabled = !this.isSingleSelection() || this.isSearchMode();
      this.items[1].items[3].disabled = !this.isSingleSelection() || this.isSearchMode();
      this.items[1].items[4].disabled = !this.isSingleSelection() || this.isSearchMode();
    }
  }

  dbclick($event: MouseEvent, mapping: any) {
    // let target: any = $event.target;
    // if (target.className != "p-checkbox-icon") {
    //   if (mapping) {
    //     this.router.navigate(["/meter-data"], {queryParams: {id: mapping.i, method: mapping.me, address: mapping.me == IMBusMethod.PRI ? mapping.a : mapping.i}});
    //   }
    // }
  }

  protected readonly closed = closed;

  //REFACTOR

  //Lock UI
  lockUI() {
    this.blockUIStart();
  }

  //Unlock UI
  unlockUI() {
    this.blockUIStop();
  }

  //Create BACnet Object action
  createBACnetObject(records: IBACnetMBusMappingRecord[]) {
    this.lockUI();
    let successCount = 0;
    let errorCount = 0;
    from(records).pipe(
      concatMap(record => {
        return this.mappingService.createBACnetObjectForMappingRecord(record.i).pipe(catchError(err => {
            errorCount++;
            return EMPTY;
          }
        ))
      })
    ).subscribe({
      next: (e) => {
        successCount++;
        this.updateTableRow(e);
      },
      error: (err) => {
        errorCount++;
      },
      complete: () => {
        if (errorCount > 0) {
          this.toastService.warning("Objects created: " + successCount + "\n" + "Objects not created: " + errorCount);
        } else {
          this.toastService.success("Objects created: " + successCount + "\n" + "Objects not created: " + errorCount);
        }
        this.clearSelection();
        this.unlockUI();
      }}
    )
  }

  //Delete BACnet Object action
  deleteBACnetObject(records: IBACnetMBusMappingRecord[]) {
    this.lockUI();
    let successCount = 0;
    let errorCount = 0;
    from(records).pipe(
      concatMap(ev => {
        return this.mappingService.deleteBACnetObjectForMappingRecord(ev.i).pipe(catchError(err => {
            errorCount++;
            return EMPTY;
          }
        ))
      })
    ).subscribe({
      next: (e) => {
        successCount++;
        this.updateTableRow(e);
      },
      error: (err) => {
        errorCount++;
      },
      complete: () => {
        if (errorCount > 0) {
          this.toastService.warning("Objects deleted: " + successCount + "\n" + "Objects not deleted: " + errorCount);
        } else {
          this.toastService.success("Objects deleted: " + successCount + "\n" + "Objects not deleted: " + errorCount);
        }
        this.clearSelection();
        this.unlockUI();
      }
    })
  }

  //Delete mapping record
  deleteMeter(records: IBACnetMBusMappingRecord[]) {
    this.lockUI();
    let successCount = 0;
    let errorCount = 0;
    from(records).pipe(
      concatMap(ev => {
        return this.mappingService.deleteBacnetMBusMappingRecord(ev.i).pipe(catchError(err => {
            errorCount++;
            return EMPTY;
          }
        ))
      })
    ).subscribe({
      next: (e) => {
        successCount++;
        // let index = this.mbusMappings.indexOf(this.selectedMbusMappings[0], 0);
        // this.mbusMappings.splice(index, 1);
      },
      error: (err) => {
        errorCount++;
      },
      complete: () => {
        if (errorCount > 0) {
          this.toastService.warning("Mapping records deleted: " + successCount + "\n" + "Mapping records not deleted: " + errorCount);
        } else {
          this.toastService.success("Mapping records deleted: " + successCount + "\n" + "Mapping records not deleted: " + errorCount);
        }
        this.clearSelection();
        this.refreshMappings();
        this.unlockUI();
      }
    });
  }


  onConnPropFormSubmit() {
    this.lockUI();
    let successCount = 0;
    let errorCount = 0;
    if (this.connPropForm.valid) {
      let connProp: IBACnetMBusMappingConnectionProp = {} as IBACnetMBusMappingConnectionProp;
      connProp.mbus_method = this.connPropForm.get("connMethod")?.value;
      connProp.mbus_baud_rate = this.connPropForm.get("connBaudRate")?.value;
      connProp.mbus_nke = this.connPropForm.get("connNKE")?.value;
      connProp.mbus_rst = this.connPropForm.get("connRST")?.value;
      connProp.interval = this.connPropForm.get("connInterval")?.value;

      from(this.mappingsToEditConnnection).pipe(
        concatMap(ev => {
          return this.mappingService.updateBacnetMBusMappingConnectionProperties(ev.i, connProp).pipe(catchError(err => {
              errorCount++;
              return EMPTY;
            }
          ))
        })
      ).subscribe({
        next: (e) => {
          successCount++;
          this.updateTableRow(e);
        },
        error: (err) => {
          errorCount++;
        },
        complete: () => {
          if (errorCount > 0) {
            this.toastService.warning("Mapping records updated: " + successCount + "\n" + "Mapping records not updated: " + errorCount);
          } else {
            this.toastService.success("Mapping records updated: " + successCount + "\n" + "Mapping records not updated: " + errorCount);
          }
          this.clearSelection();
          this.hideConnPropDialog();
          this.refreshMappings();
          this.unlockUI();
        },
      });
    }
  }

  onPasteRecordsSubmit() {
    this.lockUI();
    let successCount = 0;
    let errorCount = 0;
    if (this.connPropForm.valid) {
      let pasteProp: IBACnetMBusMappingPasteConf = {} as IBACnetMBusMappingPasteConf;
      pasteProp.copy_id = Number(this.copiedMapping?.i);
      pasteProp.interval = this.pasteRecordsForm.get("interval")?.value;
      pasteProp.records = this.pasteRecordsForm.get("records")?.value;
      pasteProp.rst = this.pasteRecordsForm.get("nke")?.value;
      pasteProp.nke = this.pasteRecordsForm.get("rst")?.value;
      pasteProp.description = this.pasteRecordsForm.get("description")?.value;
      pasteProp.baudrate = this.pasteRecordsForm.get("baudRate")?.value;
      from(this.mappingsToPaste).pipe(
        concatMap(ev => {
          return this.mappingService.pasteMappingConfiguration(ev.i, pasteProp).pipe(catchError(err => {
              errorCount++;
              return EMPTY;
            }
          ))
        })
      ).subscribe({
        next: (e) => {
          successCount++;
          this.updateTableRow(e);
        },
        error: (err) => {
          errorCount++;
        },
        complete: () => {
          if (errorCount > 0) {
            this.toastService.warning("Mapping records updated: " + successCount + "\n" + "Mapping records not updated: " + errorCount);
          } else {
            this.toastService.success("Mapping records updated: " + successCount + "\n" + "Mapping records not updated: " + errorCount);
          }
          this.clearSelection();
          this.hidePasteDialog();
          this.refreshMappings();
          this.unlockUI();
        },
      });
    }
  }
}
