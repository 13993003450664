import { Component, OnInit } from '@angular/core';
import {ICopyMeterMappingDTO, IMeterMapping, IUpdateMeterMappingDTO} from "../../core/interfaces/mapping";
import {Router} from "@angular/router";
import {MappingService} from "../../core/services/mapping.service";
import {MeterService} from "../../core/services/meter.service";
import {MegaMenuItem, MessageService} from "primeng/api";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ENavigatorMode} from "../../core/interfaces/app-enum";
import {concatMap, filter, finalize, from} from "rxjs";

@Component({
  selector: 'app-meter-table',
  templateUrl: './meter-table.component.html',
  styleUrls: ['./meter-table.component.scss']
})
export class MeterTableComponent implements OnInit {



  mappings: IMeterMapping[] = [];
  selectedMappings: IMeterMapping[] = [];
  baudRateRequired: boolean = false;
  intervalRequired: boolean = false;
  constructor(private router: Router, private mappingService: MappingService, private meterService: MeterService, private messageService: MessageService, private fb: FormBuilder) { }

  editRecordForm!: FormGroup;
  pasteRecordsForm!: FormGroup;
  copiedMapping: IMeterMapping | undefined;

  mode: ENavigatorMode = ENavigatorMode.METERS;

  cars: any = [
    {vin: "ZBMNDBHJFBDSHJFDS", year: 2002, brand: "Opel", color: "Blue"},
    {vin: "ZBMNDBHJFBDSHJFDS", year: 2002, brand: "Opel", color: "Blue"},
    {vin: "ZBMNDBHJFBDSHJFDS", year: 2002, brand: "Opel", color: "Blue"},
    {vin: "ZBMNDBHJFBDSHJFDS", year: 2002, brand: "Opel", color: "Blue"}
  ]
  editDialogVisible: boolean = false;
  pasteRecordsDialogVisible: boolean = false;
  checked: boolean = false;
  method: any;
  baudRates: number[] = [300,2400,9600,38400];
  intervals: number[] = [1,2,4,8,16,32,64,128,256];

  ngOnInit(): void {

    this.router.navigate(['/meters']);
    this.initializeForms();
    this.refresh();
  }

  createBACnetObjectsForRecords() {
    let recordNumbers: number[] = this.selectedMappings.map(m => m.recordNumber);
    console.log(recordNumbers);
    this.mappingService.createObjectForMappingRecords(recordNumbers).subscribe(
      e => {
        e.forEach(m => {
          this.updateTableRow(m);
        });
        this.selectedMappings = [];
        this.messageService.add({severity:'success', summary: 'Success', detail: 'BACnet objects created for ' + e.length + ' records'});
      }
    )
  }

  deleteBACnetObjectsForRecords() {
    let recordNumbers: number[] = this.selectedMappings.map(m => m.recordNumber);
    console.log(recordNumbers);
    this.mappingService.deleteObjectsForMappingRecords(recordNumbers).subscribe(
      e => {
        e.forEach(m => {
          this.updateTableRow(m);
        });
        this.selectedMappings = [];
        this.messageService.add({severity:'success', summary: 'Success', detail: 'BACnet objects deleted for ' + e.length + ' records'});
      }
    )
  }

  refresh() {
    this.mappingService.getMappings().subscribe(
      next => {
        this.resetStatuses(next);
        this.mappings = next;
        this.selectedMappings = [];

      }
    )
  }

  resetStatus(mapping: IMeterMapping) {
    if (mapping.connAddress && mapping.connBaudRate && mapping.connReadInterval) {
      mapping.status = "";
    }
  }

  resetStatuses(mappings: IMeterMapping[]) {
    mappings.forEach(e => {
      this.resetStatus(e);
    })
  }

  refreshWithMessage() {
    this.mappingService.getMappings().subscribe(
      next => {
        this.resetStatuses(next);
        this.mappings = next;
        this.selectedMappings = [];
        this.messageService.add({severity:'success', summary: 'Success', detail: 'Records refreshed'});
      }
    )
  }

  hideEditDialog() {
    this.editDialogVisible = false;
    this.selectedMappings = [];
  }

  editRecord() {
    this.editRecordForm.setValue({
      autoName: this.selectedMappings[0].bacnetAutoName,
      name: this.selectedMappings[0].bacnetObject.name,
      description: this.selectedMappings[0].bacnetObject.description,
      location: this.selectedMappings[0].bacnetObject.location,
      method: this.selectedMappings[0].connMethod,
      address: this.selectedMappings[0].connAddress,
      baudRate: this.selectedMappings[0].connBaudRate,
      interval: this.selectedMappings[0].connReadInterval
    });
    this.validateName(this.editRecordForm.get('autoName')?.value);
    this.validateAddress(this.editRecordForm.get('method')?.value);
    this.validateBaudRateAndInterval(this.editRecordForm.get('address')?.value);
    this.editDialogVisible = true;
    this.editRecordForm.updateValueAndValidity();
  }

  private validateName(autoName: boolean) {
    if (autoName) {
      this.editRecordForm.get("name")?.removeValidators(Validators.required);
      this.editRecordForm.get("name")?.setValue(this.createAutoName(this.selectedMappings[0]));
      this.editRecordForm.get("name")?.disable();
    } else {
      this.editRecordForm.get("name")?.addValidators(Validators.required);
      this.editRecordForm.get("name")?.enable();
    }
    this.editRecordForm.get("name")?.updateValueAndValidity();
  }

  private validateAddress(method: string) {
    if (method == "PRI") {
      this.editRecordForm.get("address")?.setValidators([Validators.min(1),Validators.max(250)]);
    } else {
      this.editRecordForm.get("address")?.setValidators([Validators.min(0),Validators.max(99999999),Validators.minLength(8), Validators.maxLength(8)]);
    }
    this.editRecordForm.get("address")?.updateValueAndValidity();
  }

  private validateBaudRateAndInterval(address: number) {
    if (address && address >= 0) {
      this.baudRateRequired = true;
      this.intervalRequired = true;
      this.editRecordForm.get("baudRate")?.setValidators([Validators.required]);
      this.editRecordForm.get("interval")?.setValidators([Validators.required]);
      if (!this.editRecordForm.get("interval")?.value) {
        this.editRecordForm.get("interval")?.setValue(1440);
      }
      if (!this.editRecordForm.get("baudRate")?.value) {
        this.editRecordForm.get("baudRate")?.setValue(2400);
      }
    } else {
      this.baudRateRequired = false;
      this.intervalRequired = false;
      this.editRecordForm.get("baudRate")?.removeValidators([Validators.required]);
      this.editRecordForm.get("interval")?.removeValidators([Validators.required]);
    }
    this.editRecordForm.get("baudRate")?.updateValueAndValidity();
    this.editRecordForm.get("interval")?.updateValueAndValidity();
  }

  private initializeForms() {
    this.editRecordForm = this.fb.group({
      autoName: true,
      name: '',
      description: '',
      location: '',
      method: 'PRI',
      address: ['', Validators.max(250)],
      baudRate: [2400, Validators.required],
      interval: 4
    });
    this.editRecordForm.get("autoName")?.valueChanges.subscribe(e => {
      this.validateName(e);
    });

    this.editRecordForm.get("method")?.valueChanges.subscribe(e => {
      this.validateAddress(e);
    });

    this.editRecordForm.get("address")?.valueChanges.subscribe(e => {
      this.validateBaudRateAndInterval(e);
    });

    this.pasteRecordsForm = this.fb.group({
      autoName: false,
      description: false,
      location: false,
      baudRate: false,
      interval: false
    });
  }

  selectedOneRecordWithBACNetObjectCreated(): boolean {
    return (this.selectedMappings.length == 1 && this.selectedMappings[0]?.bacnetObject != null);
  }

  selectedRecordsWithBACnetObjectCount(): number {
    return this.selectedMappings.filter(m => m.bacnetObject != null).length;
  }

  onEditRecordSubmit() {
    console.log(this.editRecordForm);
    if (this.editRecordForm.valid) {
      let updateDTO = {} as IUpdateMeterMappingDTO;
      updateDTO.autoName = this.editRecordForm.get("autoName")?.value;
      updateDTO.objectName = this.editRecordForm.get("name")?.value;
      updateDTO.description = this.editRecordForm.get("description")?.value;
      updateDTO.objectLocation = this.editRecordForm.get("location")?.value;
      updateDTO.method = this.editRecordForm.get("method")?.value;
      updateDTO.address = this.editRecordForm.get("address")?.value;
      updateDTO.baudRate = this.editRecordForm.get("baudRate")?.value;
      updateDTO.interval = this.editRecordForm.get("interval")?.value;
      this.mappingService.updateMapingRecord(this.selectedMappings[0].recordNumber, updateDTO).subscribe(
        next => {
          this.editDialogVisible = false;
          this.resetStatus(next);
          this.updateTableRow(next);
          this.selectedMappings = [];
          this.messageService.add({severity:'success', summary: 'Success', detail: 'Record updated'});
        },
        error => {
          console.log(error);
          this.messageService.add({severity:'error', summary: 'Success', detail: error.error.message});
        }
      )
    }
  }

  updateTableRow(mapping: IMeterMapping) {
    let tmp;
    tmp = this.mappings.find(r => r.recordNumber == mapping.recordNumber);
    if (tmp) {
      let index = this.mappings.indexOf(tmp);
      this.mappings[index] = mapping;
    }
  }

  createAutoName(mapping: IMeterMapping): string {
    if (mapping.bacnetAutoName) {
      if (mapping.connAddress) {
        if (mapping.meterId) {
          return mapping.meterMedium + " Meter " + mapping.meterId + "(" + "PRI" + ")";
        } else {
          if ("PRI" == mapping.connMethod) {
            return "Meter XXXXXXXX(" + mapping.connAddress + ")";
          } else {
            return "Meter " + mapping.connAddress + "(X)"
          }
        }
      }
    }
    return "Meter " + mapping.recordNumber;
  }

  copyRecord() {
    if (this.selectedMappings.length == 1) {
      this.copiedMapping = this.selectedMappings[0];
      this.selectedMappings = [];
      this.messageService.add({severity:'success', summary: 'Success', detail: 'Record copied'});
    }
  }

  pasteRecords() {
    this.pasteRecordsDialogVisible = true;
  }

  communicationTest() {
    let successCount = 0;
    let totalCount = this.selectedMappings.filter(e => (e.connMethod?.length > 0 && e.connMethod?.length > 0 && e.connAddress > 0)).length;
    from(this.selectedMappings).pipe(
      filter(e => (e.connMethod?.length > 0 && e.connMethod?.length > 0 && e.connAddress > 0)),
      concatMap(e => {
        return this.mappingService.communicationTest(e.recordNumber);
      }),
      finalize(() => {
        if (this.selectedMappings.length == successCount) {
          this.messageService.add({severity:'success', summary: 'Success', detail: 'All selected slaved connected successfully'});
        } else {
          this.messageService.add({severity:'error', summary: 'Error', detail: "Connected to " + successCount + " meters. " + "Not connected to " + (totalCount-successCount) + " meters"});
        }
        this.selectedMappings = [];
      })
    )
      .subscribe(e => {
        if(e.meterId) {
          e.status = 'V';
          successCount++;
        } else {
          e.status = 'X';
        }
        this.updateTableRow(e);
      });
  }

  ngOnDestroy(): void {
  }

  onPasteRecordsSubmit() {
    if (this.copiedMapping) {
      let copyDTO: ICopyMeterMappingDTO = {} as ICopyMeterMappingDTO;
      copyDTO.autoName = this.pasteRecordsForm.get('autoName')?.value;
      copyDTO.description = this.pasteRecordsForm.get('description')?.value;
      copyDTO.location = this.pasteRecordsForm.get('location')?.value;
      copyDTO.baudRate = this.pasteRecordsForm.get('baudRate')?.value;
      copyDTO.interval = this.pasteRecordsForm.get('interval')?.value;
      this.mappingService.copyMappingRecords(this.copiedMapping.recordNumber, this.selectedMappings.map(r => r.recordNumber), copyDTO).subscribe(
        e => {
          this.pasteRecordsDialogVisible = false;
          this.pasteRecordsForm.reset();
          e.forEach(m => {
            this.updateTableRow(m);
          })
          this.selectedMappings = [];
          this.messageService.add({severity:'success', summary: 'Success', detail: 'Record copied'});
        }
      )
    }
  }

  frequencyToInterval(freq: number) {
    switch (freq) {
      case 1:
        return "every 24h";
      case 2:
        return "every 12h";
      case 4:
        return "every 6h";
      case 8:
        return "every 3h";
      case 16:
        return "every 90min";
      case 32:
        return "every 45min";
      case 64:
        return "~every 22min";
      case 128:
        return "~every 11min";
      case 256:
        return "~every 5min";
      default:
        return 1440/freq + " min";
    }
  }

  dataRecords() {
    this.router.navigate(["/navigator/data"], {
      queryParams: {
        mapping: this.selectedMappings[0].id,
      }
    });
  }

}
