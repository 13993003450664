<div style="height: 100vh; display: flex; flex-direction: column">
  <p-menubar [model]="items">
    <ng-template pTemplate="start">
      <img src="assets/Artboard2.png">
    </ng-template>
    <ng-template pTemplate="end">
      <button pButton pRipple type="button" label="Logout" class="p-button-outlined p-button-secondary"></button>
    </ng-template>
  </p-menubar>

  <as-split direction="vertical">
    <as-split-area [size]="80">
      <as-split direction="horizontal">
        <as-split-area [size]="20">
          <app-object-tree></app-object-tree>
        </as-split-area>
        <as-split-area [size]="80">
          <router-outlet></router-outlet>
        </as-split-area>
      </as-split>
    </as-split-area>
    <as-split-area [size]="20">
      <div style="height: 100%">
        <!--            <p-terminal prompt="-> "></p-terminal>-->
        <p-scrollPanel [style]="{width: '100%', height: '100%'}">

          <p *ngFor="let message of messages">{{message}}</p>

        </p-scrollPanel>
        <!--      <div style="position:absolute; bottom: 0; background-color: lightgray; width: 100%; z-index: 10000">M-BUS log</div>-->
      </div>
    </as-split-area>
  </as-split>

<!--  <as-split direction="horizontal">-->
<!--    <as-split-area [size]="20">-->
<!--      <app-object-tree></app-object-tree>-->
<!--    </as-split-area>-->
<!--    <as-split-area [size]="80">-->
<!--      <as-split direction="vertical" unit="pixel">-->
<!--&lt;!&ndash;        <div style="flex-grow: 1; overflow-y: hidden; overflow-x: hidden">&ndash;&gt;-->
<!--          <as-split-area>-->
<!--            <router-outlet></router-outlet>-->
<!--          </as-split-area>-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <as-split-area [minSize]=120>-->
<!--          <div style="height: 100%">-->
<!--&lt;!&ndash;            <p-terminal prompt="-> "></p-terminal>&ndash;&gt;-->
<!--            <p-scrollPanel [style]="{width: '100%', height: '100%'}">-->

<!--              <p *ngFor="let message of messages">{{message}}</p>-->

<!--            </p-scrollPanel>-->
<!--            &lt;!&ndash;      <div style="position:absolute; bottom: 0; background-color: lightgray; width: 100%; z-index: 10000">M-BUS log</div>&ndash;&gt;-->
<!--          </div>-->
<!--        </as-split-area>-->
<!--      </as-split>-->
<!--    </as-split-area>-->
<!--  </as-split>-->
  <div style="height: 20px; background-color: #d4d4d4" class="flex justify-content-end">
    <div class="mr-2">Free: {{currMemoryStats?.freeMem}}B</div>
    <div>Min: {{currMemoryStats?.minMem}}B</div>
  </div>
</div>
<p-toast [style]="{'white-space': 'pre-line'}" position="bottom-center"></p-toast>
