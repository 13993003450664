 <p-toolbar>
      <div class="p-toolbar-group-left">
        <button pButton pRipple type="button" label="Communication test" class="p-button-outlined mr-2" (click)="communicationTest()"
                [disabled]="selectedSlaves.length < 1"></button>
        <button pButton pRipple type="button" [label]="searchMetersLabel" [ngClass]="isSearchMode() ? 'p-button-danger' : 'p-button-outlined'" class="mr-2" (click)="searchMeters()" [disabled]="!isFreeMode() && !isSearchMode()"></button>
        <p-splitButton label="Set" [model]="this.setMenuItems" styleClass="p-button-outlined mr-2" [disabled]="selectedSlaves.length != 1 || activeAction"></p-splitButton>
        <button pButton pRipple type="button" label="Refresh BACnet bindings" class="p-button-outlined mr-2" (click)="refreshBacnetObjects()" [disabled]="activeAction"></button>
        <button pButton pRipple type="button" label="Bind BACnet object" class="p-button-outlined mr-2" (click)="assignBacnetObject()" [disabled]="selectedSlaves.length != 1 || activeAction"></button>
            <button pButton pRipple type="button" label="Delete" class="p-button-outlined p-button-info mr-2" (click)="deleteSlaves()"></button>
        <!--    <button pButton pRipple type="button" label="Stop" class="p-button-outlined p-button-info mr-2" (click)="stop()"></button>-->
      </div>

      <div class="p-toolbar-group-right">

      </div>
    </p-toolbar>

    <p-table [style]="{'padding-bottom': '86.39px'}" [scrollable]="true" scrollHeight="flex" [(selection)]="selectedSlaves" [value]="mBusSlaves" styleClass="p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th rowspan="2" colspan="1" style="background-color: #ffc000"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
          <th colspan="2" style="background-color: #ffc000" (click)="sendMessage()">M-Bus request</th>
          <th colspan="6" style="background-color: #0070C0">M-Bus response</th>
          <th colspan="2" style="background-color: #92D050">BACnet</th>
        </tr>
        <tr>
          <th style="background-color: #ffc000">Method</th>
          <th style="background-color: #ffc000">Baud Rate</th>
          <th style="background-color: #0070C0">Address</th>
          <th style="background-color: #0070C0">ID</th>
          <th style="background-color: #0070C0">Man</th>
          <th style="background-color: #0070C0">Ver</th>
          <th style="background-color: #0070C0">Medium</th>
          <th style="background-color: #0070C0">Test</th>
          <th style="background-color: #92D050">Object</th>
          <th style="background-color: #92D050">Name</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-slave>
        <tr [ngClass]="{'duplicate' : isDuplicate(slave)}">
          <td>
            <p-tableCheckbox [value]="slave"></p-tableCheckbox>
          </td>
          <td>{{slave.method}}</td>
          <td>{{slave.baudrate}}</td>
          <td>{{slave.priAddress}}</td>
          <td>{{slave.secAddress}}</td>
          <td>{{slave.manufacture}}</td>
          <td>{{slave.version}}</td>
          <td>{{slave.medium}}</td>
          <td>{{slave.connectionStatus}}</td>
          <td>{{slave.bacnetObject?.type}} {{slave.bacnetObject?.identifier}}</td>
          <td>{{slave.bacnetObject?.name}}</td>
        </tr>
      </ng-template>
    </p-table>

<!--<p-toast position="bottom-center"></p-toast>-->

<p-dialog [style]="{width: '50vw', height: '600px'}" header="Search meters" [(visible)]="searchMetersDialogVisible" [modal]="true">
  <form [formGroup]="searchMetersForm" (ngSubmit)="onSearchMetersSubmit()">
    <div class="formgrid grid mt-3">
      <div class="col-12 field-checkbox">
        <p-checkbox formControlName="erase" [binary]="true" id="erase" inputId="erase"></p-checkbox>
        <label for="erase">Erase list</label>
      </div>
      <div class="col-12 field">
        <label for="method" class="block">Method</label>
        <div class="flex" id="method">
          <p-radioButton name="method" value="PRI" formControlName="method" id="pri" inputId="pri"></p-radioButton>
          <label class="mr-2" for="pri">PRI</label>
          <p-radioButton name="method" value="SEC" formControlName="method" id="sec" inputId="sec"></p-radioButton>
          <label for="sec">SEC</label>
        </div>
      </div>
      <div *ngIf="searchMetersForm.get('method')?.value == 'PRI'" class="col-12 field">
        <label for="minRange" class="block">Min range<span class="p-error">*</span></label>
        <p-inputNumber formControlName="minRange" inputId="minRange" id="minRange"></p-inputNumber>
        <small *ngIf="!searchMetersForm?.get('minRange')?.valid && searchMetersForm.get('minRange')?.touched" id="invalidMinRange" class="p-error block">Min value is required (0-250)</small>
      </div>
      <div *ngIf="searchMetersForm.get('method')?.value == 'PRI'" class="col-12 field">
        <label for="maxRange" class="block">Max range<span class="p-error">*</span></label>
        <p-inputNumber formControlName="maxRange" inputId="maxRange" id="maxRange"></p-inputNumber>
        <small *ngIf="!searchMetersForm?.get('maxRange')?.valid && searchMetersForm.get('maxRange')?.touched" id="invalidMaxRange" class="p-error block">Max value is required (0-250)</small>
      </div>
      <div class="col-12 field">
        <label for="baudRate" class="block">Baud rate<span class="p-error">*</span></label>
        <p-dropdown id="baudRate" inputId="baudRate" [options]="baudRates" formControlName="baudRate">
          <ng-template let-baud pTemplate="selectedItem">
            <div>
              <div>{{baud}} bps</div>
            </div>
          </ng-template>
          <ng-template let-baud pTemplate="item">
            <div>
              <div>{{baud}} bps</div>
            </div>
          </ng-template>
        </p-dropdown>
        <!--            <p-inputNumber id="baudRate" formControlName="baudRate" [useGrouping]="false"></p-inputNumber>-->
        <small *ngIf="!searchMetersForm?.get('baudRate')?.valid && searchMetersForm.get('baudRate')?.touched" id="invalidBaudRare" class="p-error block">Baud rate is required if address is not empty</small>
      </div>
    </div>
    <p-button [disabled]="!searchMetersForm.valid" type="submit" class="mr-2" label="Search"></p-button>
    <p-button label="Cancel" (click)="hideSearchMetersDialog()"></p-button>
  </form>
</p-dialog>

<p-dialog [style]="{width: '50vw', height: '600px'}" header="Change address" [(visible)]="changeAddressDialogVisible" [modal]="true">
  <form [formGroup]="changeAddressForm" (ngSubmit)="onChangeAddressSubmit()">
    <div class="formgrid grid mt-3">
      <div class="col-12 field">
        <label for="oldAddress" class="block">Old address</label>
        <input id="oldAddress" type="text" formControlName="oldAddress" pInputText />
      </div>
      <div class="col-12 field">
        <label for="newAddress" class="block">New address<span class="p-error">*</span></label>
        <input id="newAddress" type="text" formControlName="newAddress" pInputText />
        <small *ngIf="!changeAddressForm?.get('newAddress')?.valid && changeAddressForm.get('newAddress')?.touched" id="invalidNewAddress" class="p-error block">Value is required (1-250)</small>
      </div>
    </div>
    <p-button [disabled]="!changeAddressForm.valid" type="submit" class="mr-2" label="Change"></p-button>
    <p-button label="Cancel" (click)="hideChangeAddressDialog()"></p-button>
  </form>
</p-dialog>

<p-dialog [style]="{width: '50vw', height: '600px'}" header="Change Id" [(visible)]="changeIdDialogVisible" [modal]="true">
  <form [formGroup]="changeIdForm" (ngSubmit)="onChangeIdSubmit()">
    <div class="formgrid grid mt-3">
      <div class="col-12 field">
        <label for="oldId" class="block">Old Id</label>
        <input id="oldId" type="text" formControlName="oldId" pInputText />
      </div>
      <div class="col-12 field">
        <label for="newId" class="block">New Id<span class="p-error">*</span></label>
        <input id="newId" type="text" formControlName="newId" pInputText />
        <small *ngIf="!changeIdForm?.get('newId')?.valid && changeIdForm.get('newId')?.touched" id="invalidNewId" class="p-error block">8 digits required</small>
      </div>
    </div>
    <p-button [disabled]="!changeIdForm.valid" type="submit" class="mr-2" label="Change"></p-button>
    <p-button label="Cancel" (click)="hideChangeIdDialog()"></p-button>
  </form>
</p-dialog>

<p-dialog [style]="{width: '50vw', height: '600px'}" header="Change Baud rate" [(visible)]="changeBaudRateDialogVisible" [modal]="true">
  <form [formGroup]="changeBaudRateForm" (ngSubmit)="onChangeBaudRateSubmit()">
    <div class="formgrid grid mt-3">
      <div class="col-12 field">
        <label for="newBaudRate" class="block">New Baud rate<span class="p-error">*</span></label>
        <input id="newBaudRate" type="text" formControlName="newBaudRate" pInputText />
        <small *ngIf="!changeBaudRateForm?.get('newBaudRate')?.valid && changeBaudRateForm.get('newBaudRate')?.touched" id="invalidNewBaudRate" class="p-error block">Value is required</small>
      </div>
    </div>
    <p-button [disabled]="!changeBaudRateForm.valid" type="submit" class="mr-2" label="Change"></p-button>
    <p-button label="Cancel" (click)="hideChangeIdDialog()"></p-button>
  </form>
</p-dialog>

<p-dialog [style]="{width: '50vw', height: '600px'}" header="Assign BACnet object" [(visible)]="assignBacnetObjectFormVisible" [modal]="true">
  <form (ngSubmit)="onAssignBacnetObjectSubmit()">
    <div class="formgrid grid mt-3">
      <div class="col-12 field">
    <p-dropdown [options]="bacnetMappings" [(ngModel)]="selectedBacnetMapping" [filter]="true" [showClear]="true" placeholder="Select BACnet object" name="object">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="selectedBacnetMapping">
          <div>{{selectedBacnetMapping?.bacnetObject?.type}}{{selectedBacnetMapping?.bacnetObject?.identifier}} {{selectedBacnetMapping?.bacnetObject?.name}}</div>
        </div>
      </ng-template>
      <ng-template let-mapping pTemplate="item">
        <div>{{mapping.bacnetObject?.type}}{{mapping.bacnetObject?.identifier}} {{mapping.bacnetObject?.name}}</div>
      </ng-template>
    </p-dropdown>
      </div>
    </div>
    <p-button [disabled]="!assignBacnetObjectForm.valid" type="submit" class="mr-2" label="Change"></p-button>
    <p-button label="Cancel" (click)="hideAssignBacnetObjectDialog()"></p-button>
  </form>
</p-dialog>

<p-dialog [style]="{width: '50vw', height: '600px'}" header="Connection test" [(visible)]="connectionTestFormVisible" [modal]="true">
  <form [formGroup]="connectionForm" (ngSubmit)="onConnectionTestFormSubmit()">
    <div class="formgrid grid mt-3">
      <div class="col-12 field">
        <label for="method" class="block">Method</label>
        <div class="flex" id="connectionTestMethod">
          <p-radioButton name="method" value="PRI" formControlName="method" id="connectionTestPri" inputId="connectionTestPri"></p-radioButton>
          <label class="mr-2" for="pri">PRI</label>
          <p-radioButton name="method" value="SEC" formControlName="method" id="connectionTestSec" inputId="connectionTestPri"></p-radioButton>
          <label for="sec">SEC</label>
        </div>
      </div>
      <div class="col-12 field">
        <label for="connectionTestBaudRate" class="block">Baud rate<span class="p-error">*</span></label>
        <p-dropdown id="connectionTestBaudRate" inputId="baudRate" [options]="baudRates" formControlName="baudRate"></p-dropdown>
        <small *ngIf="!connectionForm?.get('baudRate')?.valid && connectionForm.get('baudRate')?.touched" id="invalidBaudRare" class="p-error block">Value required</small>
      </div>
    </div>
    <p-button [disabled]="!connectionForm.valid" type="submit" class="mr-2" label="Change"></p-button>
    <p-button label="Cancel" (click)="hideConnectionTestDialog()"></p-button>
  </form>
</p-dialog>


