import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MenubarModule} from "primeng/menubar";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { NavigatorComponent } from './navigator/navigator.component';
import {NavigatorModule} from "./navigator/navigator.module";
import {CoreModule} from "./core/core.module";
import { MbusConfiguratorComponent } from './mbus-configurator/mbus-configurator.component';
import {ToolbarModule} from "primeng/toolbar";
import {MbusConfiguratorModule} from "./mbus-configurator/mbus-configurator.module";
import {AngularSplitModule} from "angular-split";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {ToastModule} from "primeng/toast";
import {ObjectTreeModule} from "./object-tree/object-tree.module";
import {MeterListModule} from "./meter-list/meter-list.module";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {TerminalModule} from "primeng/terminal";
import {WebsocketService} from "./core/services/websocket.service";
import {DatePipe} from "@angular/common";
import {BlockUIModule} from "ng-block-ui";
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    MenubarModule,
    BrowserAnimationsModule,
    NavigatorModule,
    MbusConfiguratorModule,
    AngularSplitModule,
    ScrollPanelModule,
    ToastModule,
    ObjectTreeModule,
    MeterListModule,
    ButtonModule,
    RippleModule,
    TerminalModule,
    BlockUIModule.forRoot()
  ],
  providers: [WebsocketService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
