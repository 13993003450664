import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IMemoryStats} from "../interfaces/general";

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  baseUrl = environment.apiBaseUrl;
  memoryStatsUrl = this.baseUrl + 'api/memory'
  constructor(private http: HttpClient) { }

  getMemoryStats(): Observable<IMemoryStats> {
    return this.http.get<IMemoryStats>(this.memoryStatsUrl);
  }
}
