export enum EMBusConfState {
  IDLE = 0,
  MBUS_SEARCH = 1
}

export interface IAppState {
  state: EMBusConfState;
}

export enum ENavigatorMode {
  METERS = "METERS",
  DATA = "DATA"
}

export enum EError {
  "Unknown error" = 0, //RESPONSE_ERROR_OTHER

  //Mappings
  "Meter ID duplicated" = 1, //RESPONSE_ERROR_DUPLICATE
  "Memory limit obtained" = 2, //RESPONSE_ERROR_MEMORY_LIMIT

  "Mapping not found" = 3, //RESPONSE_ERROR_MAPPING_NOT_FOUND
  "Invalid slave ID" = 4, //RESPONSE_ERROR_INVALID_ID
  "Invalid slave address" = 5, //RESPONSE_ERROR_INVALID_ADDRESS,
  "Invalid baud rate" = 6, //RESPONSE_ERROR_INVALID_BAUD_RATE
  "Invalid method" = 7, //RESPONSE_ERROR_INVALID_METHOD
  "Invalid NKE field" = 8, //RESPONSE_ERROR_INVALID_NKE ,
  "Invalid RST field" = 9, //RESPONSE_ERROR_INVALID_RST,
  "Bacnet object already exists" = 10, //RESPONSE_ERROR_BACNET_OBJECT_EXISTS,
  "Obtained mapping records limit" = 11, //RESPONSE_ERROR_MAPPING_LIMIT,
  //Mbus
  "MBUS busy, try again later" = 40, //RESPONSE_ERROR_MBUS_BUSY,
  "Slave not found" = 41, //RESPONSE_ERROR_SLAVE_NOT_FOUND,
  "Slave could not be selected by 2nd number" = 42, //RESPONSE_ERROR_SLAVE_NOT_SELECTED,
  "Slave didn't respond for set 2nd address request" = 43, //RESPONSE_ERROR_SLAVE_2ND_ADDRESS_SET_NO_ANSWER,
  "Slave responded for set 2nd address request but didn't change it" = 44, //RESPONSE_ERROR_SLAVE_2ND_ADDRESS_SET_OK_NO_CHANGE,
  "Slave responded for set 2nd address request but not found now" = 45, //RESPONSE_ERROR_SLAVE_2ND_ADDRESS_SET_OK_LOST,
  "Slave responded for set 2nd address request but could not be selected with new address" = 46, //RESPONSE_ERROR_SLAVE_2ND_ADDRESS_SET_OK_NO_SELECT
  //Bacnet
  "BACnet object not found" = 80, //RESPONSE_ERROR_BACNET_OBJECT_NOT_FOUND
}
