import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NavigatorComponent} from "./navigator/navigator.component";
import {MbusConfiguratorComponent} from "./mbus-configurator/mbus-configurator.component";
import {MeterTableComponent} from "./navigator/meter-table/meter-table.component";
import {MeterListComponent} from "./meter-list/meter-list.component";
import {DataTableComponent} from "./navigator/data-table/data-table.component";

const routes: Routes = [
  {path: 'navigator', component: NavigatorComponent},
  {path: 'mbus-configurator', component: MbusConfiguratorComponent},
  {path: 'meter-list', component: MeterListComponent},
  {path: 'meter-data', component: DataTableComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
