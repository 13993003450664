import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {IMeterMapping, IUpdateDataRecordDTO, IUpdateMeterMappingDTO} from "../interfaces/mapping";
import {IMBusDataRecord} from "../interfaces/MBusSlave";

@Injectable({
  providedIn: 'root'
})
export class MbusDataRecordService {

  baseUrl = environment.apiBaseUrl;
  dataRecordsUrl = this.baseUrl + 'api/dataRecords/'
  createObjectsUrl = this.dataRecordsUrl + 'objects/';
  constructor(private http: HttpClient) { }

  updateDataRecord(dataRecordId: number, updateDTO: IUpdateDataRecordDTO) {
    return this.http.patch<IMBusDataRecord>(this.dataRecordsUrl + dataRecordId, updateDTO);
  }

  createObjectForMappingRecords(ids: number[]) {
    let params = new HttpParams();
    params = params.append('ids',ids.toString());
    return this.http.post<IMBusDataRecord[]>(this.createObjectsUrl, null, {params: params});
  }

  deleteObjectsForMappingRecords(ids: number[]) {
    let params = new HttpParams();
    params = params.append('ids',ids.toString());
    return this.http.delete<IMBusDataRecord[]>(this.createObjectsUrl,{params: params});
  }
}
