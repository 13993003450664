import {IBacnetObject} from "./bacnetObject";
import {IBACnetObjectType} from "./bacnet";

export interface IMeterMapping {
  id: number;
  recordNumber: number;
  bacnetObject: IBacnetObject;
  bacnetAutoName: boolean;
  connMethod: string;
  connAddress: number;
  connBaudRate: number;
  connReadInterval: number;
  meterId: number;
  meterManufacture: string;
  meterVersion: number;
  meterMedium: string;
  status: string;
}

export interface IUpdateMeterMappingDTO {
  autoName: boolean;
  objectName: string;
  description: string;
  objectLocation: string;
  method: string;
  address: number;
  baudRate: number;
  interval: number;
}

export interface ICopyMeterMappingDTO {
  autoName: boolean;
  description: boolean;
  location: boolean;
  baudRate: boolean;
  interval: boolean;
}

export interface IUpdateDataRecordDTO {
  multiplier: number;
  offset: number;
  autoName: boolean;
  objectName: string;
  description: string;
  unit: string;
  logValue: boolean;
}

export interface IMBusMappingRecord {
  meter: IMBusMeter;
  meterBacnetObject: IMBusMeterBacnetObject;
}

export interface IMBusMeter {
  priAddress: number;
  secAddress: string;
  baudRate: number;
  method: IMBusMethod;
  nke: boolean;
  rst: boolean;
  model: string;
  status: IMBusStatus
}

export enum IMBusMethod {
  PRI,
  SEC
}

export enum IMBusStatus {
  NEW,
  OK,
  ERR
}

export interface IMBusMeterBacnetObject {
  object_type: IBACnetObjectType;
  object_instance: number;
  object_name: string;
  object_description: string;
  object_interval: number;
  object_children_count: number;
  object_status_flags: number[];
}

export interface IBACnetMBusMappingRecord {
  i: number;
  a: number;
  b: number;
  me: IMBusMethod;
  n: boolean;
  r: boolean;
  m: string;
  s: IMBusStatus;
  d: IBACnetMBusDataMappingRecord[];
  bacnet_object_type: IBACnetObjectType;
  bacnet_instance: number;
  bacnet_name: string;
  bacnet_description: string;
  bacnet_interval: number;
  bacnet_records: number;
  bacnet_status: number[];
}

export interface IBACnetMBusDataMappingRecord {
  n: number;
  m: number;
  of: number;
  b: IBACnetObjectType;
  o: {
    i: number;
    na: string;
    d: string;
    u: number;
  }
  t: number;
  to: number;
}

export interface ISlaveChangeId {
  mapping_id: number;
  mbus_method: IMBusMethod;
  req_addr: number;
  new_id: number;
}

export interface ISlaveChangeAddress {
  mbus_method: IMBusMethod;
  req_addr: number;
  new_address: number;
  mapping_id: number;
}

export interface IBACnetMBusMappingRecordNew {
  mbus_id: number;
  mbus_addr: number;
  mbus_baud_rate: number;
  mbus_method: IMBusMethod;
  mbus_nke: number;
  mbus_rst: number;
}

export interface IBACnetMBusMappingConnectionProp {
  mbus_method: IMBusMethod;
  mbus_baud_rate: number;
  mbus_nke: number;
  mbus_rst: number;
  interval: number;
}

export interface IBACnetMBusMappingPasteConf {
  copy_id: number;
  description: boolean;
  baudrate: boolean;
  nke: boolean;
  rst: boolean;
  interval: boolean;
  records: boolean;
}

export interface IMBusDataRecordNew {
  mbus_method: IMBusMethod;
  mbus_addr: number;
  record_no: number;
  mult: number;
  offset: number;
  object_type: IBACnetObjectType;
  interval: number;
  trendlog: number;
}

export interface IMBusDataRec {
  r: number;
  u: string;
  v: number;
  vs: string;
  n: number;
  d: string;
  t: string;
  m: number;
  s: number;
  ta: number;
}

export interface ISlaveDataRecord {
  record_no: number;
  data_type: string;
  numeric: boolean;
  value: number;
  value_str: string;
  unit: string;
  description: string;
  type: string;
  module: number;
  storage: number;
  tariff: number;
  multiplier?: number;
  offset?: number;
  bacnet_object_type?: IBACnetObjectType;
  object_id?: number;
  object_name: string;
  object_description: string;
  object_unit: string;
  trend: number;
  trend_object_instance: number;
}
