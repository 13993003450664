import {ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import {EnsureModuleLoadedOnceGuard} from "./ensure-module-loaded-once.guard";
import {MbusNetworkService} from "./services/mbus-network.service";
import {MappingService} from "./services/mapping.service";
import {HttpClientModule} from "@angular/common/http";
import {RxStompService} from "./services/rx-stomp.service";
import {rxStompServiceFactory} from "./factory/rx-stomp-service-factory";
import {GlobalErrorHandler} from "./handlers/global-error-handler";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [MbusNetworkService, MappingService, {provide: RxStompService, useFactory: rxStompServiceFactory}, {
    // processes all errors
    provide: ErrorHandler,
    useClass: GlobalErrorHandler,
  }]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard{
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
