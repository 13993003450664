import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  private messageSubject: Subject<string> = new Subject<string>();
  messageAction$ = this.messageSubject.asObservable();

  addMessage(message: string) {
    this.messageSubject.next(message);
  }
}
