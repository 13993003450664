<!--<p-blockUI [blocked]="blockedDocument"></p-blockUI>-->

<block-ui>
<p-contextMenu #cm [model]="contextItems"></p-contextMenu>
  <div style="display: flex; flex-direction: column; height: 100%">
    <p-menubar [model]="items">
      <ng-template pTemplate="start">
        <button pButton pRipple type="button" pTooltip="Add offline meter" [disabled]="isSearchMode()" (click)="openNewMeterDialog()" [showDelay]="500" icon="pi pi-plus" class="p-button-lg p-button-text p-button-plain"></button>
        <button pButton pRipple type="button" [disabled]="!isMultipleSelection() || isSearchMode()" pTooltip="Delete record" (click)="deleteMeter(selectedMbusMappings)" [showDelay]="500" icon="pi pi-trash" class="p-button-lg p-button-text p-button-plain"></button>
        <button pButton pRipple type="button" [disabled]="true" pTooltip="Refresh gateway data" [showDelay]="500" icon="pi pi-refresh" class="p-button-lg p-button-text p-button-plain mr-4"></button>
        <button pButton pRipple type="button" [disabled]="!isSingleSelection() || isSearchMode()" pTooltip="Copy properties" (click)="copy()" [showDelay]="500" icon="pi pi-clone" class="p-button-lg p-button-text p-button-plain"></button>
        <button pButton pRipple type="button" [disabled]="!isMultipleSelection() || isSearchMode() || !copiedMapping" pTooltip="Paste properties" (click)="paste()" [showDelay]="500" icon="pi pi-book" class="p-button-lg p-button-text p-button-plain mr-4"></button>
        <button pButton pRipple type="button" [disabled]="!isSingleSelection() || isSearchMode()" pTooltip="Go to Data Records" (click)="edit()" [showDelay]="500" icon="pi pi-list" class="p-button-lg p-button-text p-button-plain"></button>
        <button pButton pRipple type="button" [disabled]="!isMultipleSelection() || isSearchMode()" pTooltip="Edit connection properties" (click)="editConnProp()" [showDelay]="500" icon="pi pi-pencil" class="p-button-lg p-button-text p-button-plain mr-4"></button>
        <button pButton pRipple type="button" pTooltip="Export CSV" (click)="exportExcel()" [showDelay]="500" icon="pi pi-file-export" class="p-button-lg p-button-text p-button-plain"></button>
      </ng-template>
      <ng-template pTemplate="end">
        <button *ngIf="isSearchMode()" label="Stop searching" pButton pRipple pTooltip="Stop searching" [showDelay]="500" (click)="stopSearch()" icon="pi pi-stop-circle" class="p-button-danger"></button>
      </ng-template>
    </p-menubar>
    <div style="flex-grow: 1; height: 100%; overflow-y: auto">
      <p-table
      #dt
      [style]="{'z-index': 0, 'height': '100%'}"
      [value]="mbusMappings"
      [scrollable]="true"
      scrollHeight="flex"
      styleClass="p-datatable-sm"
      [(selection)]="selectedMbusMappings"
      (onRowSelect)="onRowSelect()"
      (onRowUnselect)="onRowSelect()"
      (onHeaderCheckboxToggle)="onRowSelect()"
      [contextMenu]="cm"
      [(contextMenuSelection)]="selectedFromContext"
      dataKey="i">
      <ng-template pTemplate="header">
        <tr>
          <th rowspan="2" colspan="1"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
          <th colspan="8" style="background-color: #00b0f0">M-Bus properties</th>
          <th colspan="6" style="background-color: #92d050">BACnet properties</th>
        </tr>
        <tr>
          <th>Meter ID</th>
          <th>Addr</th>
          <th>Baud rate</th>
          <th>Method</th>
          <th>NKE</th>
          <th>RST</th>
          <th>Model</th>
          <th>Status</th>
          <th>Object</th>
          <th>Name</th>
          <th>Description</th>
          <th>Interval</th>
          <th>Records mapped</th>
          <th>Status</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-mapping>
        <tr (dblclick)="dbclick($event, mapping)" [pContextMenuRow]="mapping">
          <td>
            <p-tableCheckbox [value]="mapping"></p-tableCheckbox>
          </td>
          <td>{{mapping.i}}</td>
          <td>{{mapping.a}}</td>
          <td>{{mapping.b}}</td>
          <td>{{IMBusMethod[mapping.me]}}</td>
          <td>{{mapping.n == 0 ? 'X' : 'V'}}</td>
          <td>{{mapping.r == 0 ? 'X' : 'V'}}</td>
          <td>{{mapping.m}}</td>
          <td>{{mapping.s == 0 ? 'X' : 'V'}}</td>
          <td>{{IBACnetObjectTypeAbbreviation[mapping.bacnet_object_type]}}{{mapping.bacnet_instance}}</td>
          <td>{{mapping.bacnet_name}}</td>
          <td>{{mapping.bacnet_description}}</td>
          <td *ngIf="!mapping.bacnet_interval"></td>
          <td *ngIf="mapping.bacnet_interval">{{mapping.bacnet_interval}} min</td>
          <td>{{mapping.bacnet_records}}</td>
          <td>{{mapping.bacnet_status}}</td>
        </tr>
      </ng-template>
    </p-table>
    </div>
  </div>



<p-dialog header="Add new meter manually"
  [(visible)]="newMeterDialogVisible"
  [modal]="true">
  <form [formGroup]="newMeterForm" (ngSubmit)="onNewMeterSubmit()">
    <p-fieldset legend="Info">
      Add M-Bus meter record manually. It should be used when the meter is not active in the network or you don't have access to the network. Only slaves with unique ID will be added to the list. You should test communication with slave when possible.
    </p-fieldset>
    <div class="formgrid grid mt-4">
      <div class="col-12 field">
        <label for="newMeterMethod" class="block">Method</label>
        <div class="flex" id="newMeterMethod">
          <p-radioButton name="newMeterMethod" [value]="IMBusMethod.PRI" formControlName="newMeterMethod" id="pri" inputId="pri"></p-radioButton>
          <label class="mr-2" for="pri">PRI</label>
          <p-radioButton name="newMeterMethod" [value]="IMBusMethod.SEC" formControlName="newMeterMethod" id="sec" inputId="sec"></p-radioButton>
          <label for="sec">SEC</label>
        </div>
      </div>
      <div class="col-12 field">
        <label for="newMeterId" class="block">Meter ID</label>
        <input id="newMeterId" type="text" formControlName="newMeterId" pInputText />
        <small *ngIf="newMeterForm.hasError('oneAddressRequired') && (newMeterForm?.get('newMeterAddress')?.touched || newMeterForm?.get('newMeterId')?.touched)" id="invalidNewMeterAddress" class="p-error block">Meter ID required</small>
      </div>
      <div class="col-12 field">
        <label for="newMeterAddress" class="block">Meter address</label>
        <input id="newMeterAddress" type="text" formControlName="newMeterAddress" pInputText />
      </div>
      <div class="col-12 field">
        <label for="newMeterBaudRate" class="block">Baud rate</label>
        <p-dropdown id="newMeterBaudRate" inputId="newMeterBaudRate" [options]="baudRates" formControlName="newMeterBaudRate">
          <ng-template let-baud pTemplate="selectedItem">
            <div>
              <div>{{baud}} bps</div>
            </div>
          </ng-template>
          <ng-template let-baud pTemplate="item">
            <div>
              <div>{{baud}} bps</div>
            </div>
          </ng-template>
        </p-dropdown>
        <small *ngIf="!newMeterForm?.get('baudRate')?.valid && newMeterForm?.get('baudRate')?.touched" id="invalidBaudRare" class="p-error block">Baud rate is required if address is not empty</small>
      </div>
      <div class="col-12 field-checkbox">
        <p-checkbox formControlName="newMeterNKE" [binary]="true" id="newMeterNKE" inputId="newMeterNKE"></p-checkbox>
        <label for="newMeterNKE">SND_NKE</label>
      </div>
      <div class="col-12 field-checkbox">
        <p-checkbox formControlName="newMeterRST" [binary]="true" id="newMeterRST" inputId="newMeterRST"></p-checkbox>
        <label for="newMeterRST">SND_RST</label>
      </div>
    </div>
    <p-button [disabled]="!newMeterForm?.valid" type="submit" class="mr-2" label="Add"></p-button>
    <p-button label="Cancel" (click)="hideNewMeterDialog()"></p-button>
  </form>

</p-dialog>

<p-dialog [style]="{width: '50vw', height: '600px'}" header="Search meters" [(visible)]="searchMetersDialogVisible" [modal]="true">
  <form [formGroup]="searchMetersForm" (ngSubmit)="onSearchMetersSubmit()">
    <p-fieldset legend="Info">
      Start M-Bus search slaves procedure. Only slaves with unique ID will be added to the list. It can take a lot of time to scan entire network.
    </p-fieldset>
    <div class="formgrid grid mt-4">
      <div class="col-12 field-checkbox">
        <p-checkbox formControlName="erase" [binary]="true" id="erase" inputId="erase"></p-checkbox>
        <label for="erase">Erase list</label>
      </div>
      <div class="col-12 field">
        <label for="method" class="block">Method</label>
        <div class="flex" id="method">
          <p-radioButton name="method" [value]="IMBusMethod.PRI" formControlName="method" id="pri" inputId="pri"></p-radioButton>
          <label class="mr-2" for="pri">PRI</label>
          <p-radioButton name="method" [value]="IMBusMethod.SEC" formControlName="method" id="sec" inputId="sec"></p-radioButton>
          <label for="sec">SEC</label>
<!--          <p-radioButton name="method" value="PRI" formControlName="method" id="pri" inputId="pri"></p-radioButton>-->
<!--          <label class="mr-2" for="pri">PRI</label>-->
<!--          <p-radioButton name="method" value="SEC" formControlName="method" id="sec" inputId="sec"></p-radioButton>-->
<!--          <label for="sec">SEC</label>-->
        </div>
      </div>
      <div *ngIf="searchMetersForm.get('method')?.value == IMBusMethod.PRI" class="col-12 field">
        <label for="minRange" class="block">Min range<span class="p-error">*</span></label>
        <p-inputNumber formControlName="minRange" inputId="minRange" id="minRange"></p-inputNumber>
        <small *ngIf="!searchMetersForm?.get('minRange')?.valid && searchMetersForm.get('minRange')?.touched" id="invalidMinRange" class="p-error block">Min value is required (0-250)</small>
      </div>
      <div *ngIf="searchMetersForm.get('method')?.value == IMBusMethod.PRI" class="col-12 field">
        <label for="maxRange" class="block">Max range<span class="p-error">*</span></label>
        <p-inputNumber formControlName="maxRange" inputId="maxRange" id="maxRange"></p-inputNumber>
        <small *ngIf="!searchMetersForm?.get('maxRange')?.valid && searchMetersForm.get('maxRange')?.touched" id="invalidMaxRange" class="p-error block">Max value is required (0-250)</small>
      </div>
      <div class="col-12 field">
        <label for="baudRate" class="block">Baud rate<span class="p-error">*</span></label>
        <p-dropdown id="baudRate" inputId="baudRate" [options]="baudRates" formControlName="baudRate">
          <ng-template let-baud pTemplate="selectedItem">
            <div>
              <div>{{baud}} bps</div>
            </div>
          </ng-template>
          <ng-template let-baud pTemplate="item">
            <div>
              <div>{{baud}} bps</div>
            </div>
          </ng-template>
        </p-dropdown>
        <!--            <p-inputNumber id="baudRate" formControlName="baudRate" [useGrouping]="false"></p-inputNumber>-->
        <small *ngIf="!searchMetersForm?.get('baudRate')?.valid && searchMetersForm.get('baudRate')?.touched" id="invalidBaudRare" class="p-error block">Baud rate is required if address is not empty</small>
      </div>
    </div>
    <p-button [disabled]="!searchMetersForm.valid" type="submit" class="mr-2" label="Search"></p-button>
    <p-button label="Cancel" (click)="hideSearchMetersDialog()"></p-button>
  </form>
</p-dialog>


<p-dialog [style]="{width: '50vw', height: '600px'}" header="Send change ID request" [(visible)]="changeIDDialogVisible" [modal]="true">
  <form [formGroup]="changeIDForm" (ngSubmit)="onChangeIDSubmit(selectedMbusMappings[0])">
    <p-fieldset legend="Info">
      Set M-Bus slave secondary address (ID) property. Slave can reject the request and not change its secondary address (ID).
    </p-fieldset>
    <div class="formgrid grid mt-4">
      <div class="col-12 field">
        <label for="changeIDNewID" class="block">New ID<span class="p-error">*</span></label>
        <p-inputNumber [useGrouping]="false" [min]="0" [max]="99999999" formControlName="changeIDNewID" inputId="changeIDNewID" id="changeIDNewID"></p-inputNumber>
        <small *ngIf="!changeIDForm?.get('changeIDNewID')?.valid && changeIDForm.get('changeIDNewID')?.touched" id="invalidChangeIDNewID" class="p-error block">Value is required</small>
      </div>
    </div>
    <p-button [disabled]="!changeIDForm.valid" type="submit" class="mr-2" label="Send"></p-button>
    <p-button label="Cancel" (click)="hideChangeIDDialog()"></p-button>
  </form>
</p-dialog>

<p-dialog [style]="{width: '50vw', height: '600px'}" header="Send change Address request" [(visible)]="changeAddressDialogVisible" [modal]="true">
  <form [formGroup]="changeAddressForm" (ngSubmit)="onChangeAddressSubmit(selectedMbusMappings[0])">
    <p-fieldset legend="Info">
      Set M-Bus slave primary address property. Slave can reject the request and not change its primary address.
    </p-fieldset>
    <div class="formgrid grid mt-4">
      <div class="col-12 field">
        <label for="changeAddressNewAddress" class="block">New Address<span class="p-error">*</span></label>
        <p-inputNumber [useGrouping]="false" [min]="0" [max]="250" formControlName="changeAddressNewAddress" inputId="changeAddressNewAddress" id="changeAddressNewAddress"></p-inputNumber>
        <small *ngIf="!changeAddressForm?.get('changeAddressNewAddress')?.valid && changeAddressForm.get('changeAddressNewAddress')?.touched" id="invalidChangeAddressNewAddress" class="p-error block">Value is required</small>
      </div>
    </div>
    <p-button [disabled]="!changeAddressForm.valid" type="submit" class="mr-2" label="Send"></p-button>
    <p-button label="Cancel" (click)="hideChangeAddressDialog()"></p-button>
  </form>
</p-dialog>

<p-dialog [style]="{width: '50vw', height: '600px'}" header="Send change Baud rate request" [(visible)]="changeBaudRateDialogVisible" [modal]="true">
  <form [formGroup]="changeBaudRateForm" (ngSubmit)="onChangeBaudRateSubmit()">
    <p-fieldset legend="Info">
      Set M-Bus slave baud rate property. Slave can reject the request and not change its baud rate.
    </p-fieldset>
    <div class="formgrid grid mt-4">
      <div class="col-12 field">
        <label for="changeBaudRateNewBaudRate" class="block">Baud rate<span class="p-error">*</span></label>
        <p-dropdown id="changeBaudRateNewBaudRate" inputId="changeBaudRateNewBaudRate" [options]="baudRates" formControlName="changeBaudRateNewBaudRate">
          <ng-template let-baud pTemplate="selectedItem">
            <div>
              <div>{{baud}} bps</div>
            </div>
          </ng-template>
          <ng-template let-baud pTemplate="item">
            <div>
              <div>{{baud}} bps</div>
            </div>
          </ng-template>
        </p-dropdown>
        <!--            <p-inputNumber id="baudRate" formControlName="baudRate" [useGrouping]="false"></p-inputNumber>-->
        <small *ngIf="!changeBaudRateForm?.get('changeBaudRateNewBaudRate')?.valid && changeBaudRateForm.get('changeBaudRateNewBaudRate')?.touched" id="invalidChangeBaudRateNewBaudRate" class="p-error block">Baud rate is required if address is not empty</small>
      </div>
<!--      <div class="col-12 field">-->
<!--        <label for="changeBaudRateNewBaudRate" class="block">New Address<span class="p-error">*</span></label>-->
<!--        <p-inputNumber [useGrouping]="false" [min]="0" [max]="250" formControlName="changeBaudRateNewBaudRate" inputId="changeBaudRateNewBaudRate" id="changeBaudRateNewBaudRate"></p-inputNumber>-->
<!--        <small *ngIf="!changeBaudRateForm?.get('changeBaudRateNewBaudRate')?.valid && changeBaudRateForm.get('changeBaudRateNewBaudRate')?.touched" id="invalidChangeBaudRateNewBaudRate" class="p-error block">Value is required</small>-->
<!--      </div>-->
    </div>
    <p-button [disabled]="!changeBaudRateForm.valid" type="submit" class="mr-2" label="Send"></p-button>
    <p-button label="Cancel" (click)="hideChangeBaudRateDialog()"></p-button>
  </form>
</p-dialog>

<p-dialog header="Paste records" [(visible)]="pasteRecordsDialogVisible" [modal]="true">
  <form [formGroup]="pasteRecordsForm" (ngSubmit)="onPasteRecordsSubmit()">
    <p-fieldset legend="Info">
      Select all the properties you want to paste for selected meter records.
    </p-fieldset>
    <div class="formgrid grid mt-4">
      <div class="col-12">
        <div class="grid">
<!--          <div class="col-12 field">-->
<!--            <label for="pasteDescription" class="block font-bold">Dynamic name</label>-->
<!--            <div class="flex align-items-center justify-content-between">-->
<!--              <label class="paste-label">{{copiedMapping?.bacnetAutoName ? 'V' : 'X'}}</label>-->
<!--              <p-inputSwitch id="pasteAutoName" formControlName="autoName"></p-inputSwitch>-->
<!--            </div>-->
<!--          </div>-->
          <div class="col-12 field">
            <label for="pasteDescription" class="block font-bold">Description</label>
            <div class="flex align-items-center justify-content-between">
              <label class="paste-label">{{copiedMapping?.bacnet_description}}</label>
              <p-inputSwitch id="pasteDescription" formControlName="description"></p-inputSwitch>
            </div>
          </div>
<!--          <div class="col-12 field">-->
<!--            <label for="pasteLocation" class="block font-bold">Location</label>-->
<!--            <div class="flex align-items-center justify-content-between">-->
<!--              <label class="paste-label">{{copiedMapping?.bacnetObject?.location}}</label>-->
<!--              <p-inputSwitch id="pasteLocation" formControlName="location"></p-inputSwitch>-->
<!--            </div>-->
<!--          </div>-->
          <div class="col-12 field">
            <label for="pasteBaudrate" class="block font-bold">Baud rate</label>
            <div class="flex align-items-center justify-content-between">
              <label class="paste-label">{{copiedMapping?.b}}</label>
              <p-inputSwitch id="pasteBaudrate" formControlName="baudRate"></p-inputSwitch>
            </div>
          </div>
          <div class="col-12 field">
            <label for="pasteNKE" class="block font-bold">NKE</label>
            <div class="flex align-items-center justify-content-between">
              <label class="paste-label">{{copiedMapping?.n}}</label>
              <p-inputSwitch id="pasteNKE" formControlName="nke"></p-inputSwitch>
            </div>
          </div>
          <div class="col-12 field">
            <label for="pasteRST" class="block font-bold">RST</label>
            <div class="flex align-items-center justify-content-between">
              <label class="paste-label">{{copiedMapping?.r}}</label>
              <p-inputSwitch id="pasteRST" formControlName="rst"></p-inputSwitch>
            </div>
          </div>
          <div class="col-12 field">
            <label for="pasteInterval" class="block font-bold">Interval</label>
            <div class="flex align-items-center justify-content-between">
              <label class="paste-label">{{copiedMapping?.bacnet_interval}}</label>
              <p-inputSwitch id="pasteInterval" formControlName="interval"></p-inputSwitch>
            </div>
          </div>
          <div class="col-12 field">
            <label for="pasteRecords" class="block font-bold">Record mappings</label>
            <div class="flex align-items-center justify-content-between">
              <label class="paste-label">Records: {{copiedMapping?.bacnet_records}}</label>
              <p-inputSwitch id="pasteRecords" formControlName="records"></p-inputSwitch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-button [disabled]="!pasteRecordsForm.valid" type="submit" class="mr-2" label="Save"></p-button>
    <p-button label="Cancel"></p-button>
  </form>
</p-dialog>

<p-dialog [style]="{width: '50vw', height: '600px'}" header="Set connection properties" [(visible)]="connPropDialogVisible" [modal]="true">
  <form [formGroup]="connPropForm" (ngSubmit)="onConnPropFormSubmit()">
    <p-fieldset legend="Info">
      Below properties defines how master will send requests to slave. They won't be used as slave properties. To change slave parameters choose different action.
    </p-fieldset>

    <div class="formgrid grid mt-4">
      <div class="col-12 field">
        <label for="connMethod" class="block">Method</label>
        <div class="flex" id="connMethod">
          <p-radioButton name="connMethod" [value]="IMBusMethod.PRI" formControlName="connMethod" id="connMethodPri" inputId="pri"></p-radioButton>
          <label class="mr-2" for="pri">PRI</label>
          <p-radioButton name="connMethod" [value]="IMBusMethod.SEC" formControlName="connMethod" id="connMethodSec" inputId="sec"></p-radioButton>
          <label for="sec">SEC</label>
        </div>
      </div>
      <div class="col-12 field">
        <label for="connBaudrate" class="block">Baud rate<span class="p-error">*</span></label>
        <p-dropdown id="connBaudrate" inputId="connBaudrate" [options]="baudRates" formControlName="connBaudRate">
          <ng-template let-baud pTemplate="selectedItem">
            <div>
              <div>{{baud}} bps</div>
            </div>
          </ng-template>
          <ng-template let-baud pTemplate="item">
            <div>
              <div>{{baud}} bps</div>
            </div>
          </ng-template>
        </p-dropdown>
        <!--            <p-inputNumber id="baudRate" formControlName="baudRate" [useGrouping]="false"></p-inputNumber>-->
        <small *ngIf="!changeBaudRateForm?.get('changeBaudRateNewBaudRate')?.valid && changeBaudRateForm.get('changeBaudRateNewBaudRate')?.touched" id="invalidChangeBaudRateNewBaudRate" class="p-error block">Baud rate is required if address is not empty</small>
      </div>
      <div class="col-12 field-checkbox">
        <p-checkbox formControlName="connNKE" [binary]="true" id="connNKE" inputId="connNKE"></p-checkbox>
        <label for="connNKE">SND_NKE</label>
      </div>
      <div class="col-12 field-checkbox">
        <p-checkbox formControlName="connRST" [binary]="true" id="connRST" inputId="connRST"></p-checkbox>
        <label for="connRST">SND_RST</label>
      </div>
      <div class="col-12 field">
        <label for="connInterval" class="block">Slave read interval (min)</label>
        <p-inputNumber formControlName="connInterval" inputId="connInterval" id="connInterval"></p-inputNumber>
        <small *ngIf="!connPropForm?.get('connInterval')?.valid && connPropForm.get('connInterval')?.touched" id="invalidConnInterval" class="p-error block">Value should be between 1-1440</small>
      </div>
    </div>
    <p-button [disabled]="!connPropForm.valid" type="submit" class="mr-2" label="Send"></p-button>
    <p-button label="Cancel" (click)="hideConnPropDialog()"></p-button>
  </form>
</p-dialog>
</block-ui>
