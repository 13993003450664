import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MbusConfiguratorComponent} from "./mbus-configurator.component";
import {RouterModule} from "@angular/router";


const routes = [
  {path: '', component: MbusConfiguratorComponent}
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class MbusConfiguratorRoutingModule {
  static components = [MbusConfiguratorComponent]
}
