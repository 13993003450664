import {ErrorHandler, Injectable} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastService} from "../services/toast.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private toastService: ToastService) {}

  handleError(error: any) {
    // Check if it's an error from an HTTP response
    // if (!(error instanceof HttpErrorResponse)) {
    //   // error = error.rejection; // get the error object
    // } else {
    //   this.toastService.error("Bad request");
    // }
    // console.error('Error from global error handler', error);
  }
}
