import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObjectTreeComponent } from './object-tree.component';
import {ObjectTreeRoutingModule} from "./object-tree-routing.module";
import {TreeModule} from "primeng/tree";



@NgModule({
  declarations: [
    ObjectTreeRoutingModule.components
  ],
  exports: [
    ObjectTreeComponent
  ],
  imports: [
    CommonModule,
    TreeModule
  ]
})
export class ObjectTreeModule { }
