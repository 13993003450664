import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavigatorRoutingModule} from "./navigator-routing.module";
import {SplitterModule} from "primeng/splitter";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TableModule} from "primeng/table";
import {AngularSplitModule} from "angular-split";
import {ToolbarModule} from "primeng/toolbar";
import {ButtonModule} from "primeng/button";
import {ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";
import {DialogModule} from "primeng/dialog";
import {CheckboxModule} from "primeng/checkbox";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {RadioButtonModule} from "primeng/radiobutton";
import {InputNumberModule} from "primeng/inputnumber";
import {MeterService} from "../core/services/meter.service";
import {InputSwitchModule} from "primeng/inputswitch";
import {InputMaskModule} from "primeng/inputmask";
import {MbusConfiguratorRoutingModule} from "../mbus-configurator/mbus-configurator-routing.module";
import {TooltipModule} from "primeng/tooltip";
import {DropdownModule} from "primeng/dropdown";
import { MeterTableComponent } from './meter-table/meter-table.component';
import { DataTableComponent } from './data-table/data-table.component';
import {CardModule} from "primeng/card";
import {AccordionModule} from "primeng/accordion";
import {MenubarModule} from "primeng/menubar";
import {RippleModule} from "primeng/ripple";
import {BlockUIModule} from "ng-block-ui";



@NgModule({
  declarations: [
    NavigatorRoutingModule.components,
    MeterTableComponent,
    DataTableComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    NavigatorRoutingModule,
    MbusConfiguratorRoutingModule,
    SplitterModule,
    TableModule,
    AngularSplitModule,
    ToolbarModule,
    ButtonModule,
    ToastModule,
    DialogModule,
    CheckboxModule,
    FormsModule,
    InputTextModule,
    RadioButtonModule,
    ReactiveFormsModule,
    InputNumberModule,
    InputSwitchModule,
    InputMaskModule,
    TooltipModule,
    DropdownModule,
    CardModule,
    AccordionModule,
    MenubarModule,
    RippleModule,
    BlockUIModule
  ],
  providers: [MessageService, MeterService]
})
export class NavigatorModule { }
