import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {IMBusPingResponse} from "../interfaces/MBusPingResponse";
import {INetworkSlave} from "../interfaces/mbus-network-interfaces";
import {IMBusSlave} from "../interfaces/MBusSlave";

@Injectable({
  providedIn: 'root'
})
export class MeterService {

  counter = 0;

  baseUrl = environment.apiBaseUrl;
  metersUrl = this.baseUrl + 'api/meters/';

  constructor(private http: HttpClient) { }

  pingMeter(method: string, id: number): Observable<IMBusPingResponse> {
    let params = new HttpParams();
    params = params.append('method',method);
    return this.http.get<IMBusPingResponse>(this.metersUrl + id + "/ping", {params: params});
  }

  getMeter(method: string, id: number): Observable<INetworkSlave> {
    let params = new HttpParams();
    params = params.append('method',method);
    return this.http.get<INetworkSlave>(this.metersUrl + id, {params: params});
  }

  updatePriAddress(method: string, newPriAddress: number, id: number) {
    let params = new HttpParams();
    params = params.append('method',method);
    params = params.append('newPriAddress',newPriAddress);
    return this.http.put<INetworkSlave>(this.metersUrl + id + "/priAddress", null, {params: params});
  }

  updateSecAddress(method: string, newSecAddress: number, id: number) {
    let params = new HttpParams();
    params = params.append('method',method);
    params = params.append('newSecAddress',newSecAddress);
    return this.http.put<INetworkSlave>(this.metersUrl + id + "/secAddress", null,{params: params});
  }

  updateBaudRateAddress(method: string, newBaudRate: number, id: number) {
    let params = new HttpParams();
    params = params.append('method',method);
    params = params.append('newBaudRate',newBaudRate);
    return this.http.put<INetworkSlave>(this.metersUrl + id + "/baudRate", null, {params: params});
  }

  searchMeters(method: string, minRange: number, maxRange: number, baudRate: number, erase: boolean): Observable<IMBusSlave[]> {
    let params = new HttpParams();
    params = params.append('method',method);
    params = params.append('baudRate',baudRate);
    params = params.append('minRange',minRange);
    params = params.append('maxRange',maxRange);
    params = params.append("erase", erase);
    return this.http.get<IMBusSlave[]>(this.metersUrl, {params: params});
  }

  getCounter(): number {
    return this.counter++;
  }

  startSearch() {
    return this.http.get<boolean>(this.metersUrl + "searchStart");
  }

  stopSearch() {
    return this.http.get<boolean>(this.metersUrl + "searchStop");
  }
}
