import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {BACnetObjectTreeNode} from "../interfaces/object-tree";

@Injectable({
  providedIn: 'root'
})
export class ObjectTreeService {

  baseUrl = environment.apiBaseUrl;
  objectsUrl = this.baseUrl + 'api/objects';

  constructor(private http: HttpClient) { }

  getBacnetTreeNodes() :Observable<BACnetObjectTreeNode[]> {
    return this.http.get<BACnetObjectTreeNode[]>(this.objectsUrl);
  }
}
