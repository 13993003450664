import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ISearchTask} from "../interfaces/task";

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.apiBaseUrl;
  tasksUrl = this.baseUrl + 'api/tasks/';
  searchTaskUrl = this.tasksUrl + 'search';

  createSearchTask(searchTask: ISearchTask) {
    return this.http.post<ISearchTask>(this.searchTaskUrl, searchTask);
  }

  deleteSearchTask() {
    return this.http.delete(this.searchTaskUrl);
  }
}
