import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import {Subject} from "rxjs";
import {INetworkSlave} from "../interfaces/mbus-network-interfaces";
import {IMBusSlave, IMbusSlaveSearchDTO} from "../interfaces/MBusSlave";
import {Client} from "stompjs";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";

@Injectable()
export class  WebSocketAPI {
  webSocketEndPoint: string = environment.wsBaseUrlHttp;
  topic: string = "/topic/meters";
  stompClient: any;
  private meterFoundSubject: Subject<IMbusSlaveSearchDTO> = new Subject<IMbusSlaveSearchDTO>();
  meterFoundAction$ = this.meterFoundSubject.asObservable();

  getStatus(): boolean {
    return this.stompClient?.connected;
  }

  connect() {
    console.log("Initialize WebSocket Connection");
    let ws = new SockJS(this.webSocketEndPoint);
    this.stompClient = Stomp.over(ws);
    const _this = this;
    _this.stompClient.connect({}, function () {

      _this.stompClient.subscribe(_this.topic, function (sdkEvent: any) {
        _this.onMessageReveived(sdkEvent);
      })
    })
  }

  onMessageReveived(message: any) {
    if (message.body.status == "FINISHED") {
      this.meterFoundSubject.next({} as IMbusSlaveSearchDTO);
    } else {
      this.meterFoundSubject.next(JSON.parse(message.body));
      // console.log("Message Recieved from Server :: " + message);
    }
  }

  send(message: any) {
    console.log("calling logout api via web socket");
    this.stompClient.send("/app/mbus");
  }

  disconnect() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect(()=> {});
    }
    console.log("Disconnected");
  }
}

