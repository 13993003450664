import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeterListComponent } from './meter-list.component';
import {MeterListRoutingModule} from "./meter-list-routing.module";
import {TableModule} from "primeng/table";
import {ToolbarModule} from "primeng/toolbar";
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {DropdownModule} from "primeng/dropdown";
import {RadioButtonModule} from "primeng/radiobutton";
import {CheckboxModule} from "primeng/checkbox";
import {IMqttServiceOptions, MqttModule, IMqttMessage} from "ngx-mqtt";
import {RippleModule} from "primeng/ripple";
import {InputNumberModule} from "primeng/inputnumber";
import {MegaMenuModule} from "primeng/megamenu";
import {SplitButtonModule} from "primeng/splitbutton";
import {SpeedDialModule} from "primeng/speeddial";
import {MenubarModule} from "primeng/menubar";
import {InputSwitchModule} from "primeng/inputswitch";
import {FieldsetModule} from "primeng/fieldset";
import {ContextMenuModule} from "primeng/contextmenu";
import {BlockUIModule} from "ng-block-ui";

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: '192.168.0.10',
  port: 8000,
  path: '/websocket',
  protocol: "ws"
};


@NgModule({
  declarations: [
    MeterListRoutingModule.components
  ],
  imports: [
    CommonModule,
    TableModule,
    ToolbarModule,
    ButtonModule,
    DialogModule,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    RippleModule,
    InputNumberModule,
    MegaMenuModule,
    SplitButtonModule,
    SpeedDialModule,
    MenubarModule,
    BlockUIModule,
    InputSwitchModule,
    FieldsetModule,
    ContextMenuModule,
    BlockUIModule
  ]
})
export class MeterListModule { }
