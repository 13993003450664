import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {

  constructor() { }

  getBacnetUnits(): string[] {
    return ["A","C","F","Hz","J","lm","Pa","S","W","GJ","V","K","Wh","m3","m3/h","s"]
  }
}
