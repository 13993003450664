import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {INetworkDataRecord, INetworkSlave} from "../interfaces/mbus-network-interfaces";

@Injectable({
  providedIn: 'root'
})
export class MbusNetworkService {

  baseUrl = environment.apiBaseUrl;
  mbusNetworkUrl = this.baseUrl + 'api/network/';
  mbusStartSearch = this.baseUrl + 'api/mbus/searchStart';
  mbusStopSearch = this.baseUrl + 'api/mbus/searchStop';
  mbusNetworkSlavesUrl = this.mbusNetworkUrl + 'slaves/';

  constructor(private http: HttpClient) { }

  getNetworkSlaveByPriAddress(priAddress: number): Observable<INetworkSlave> {
    return this.http.get<INetworkSlave>(this.mbusNetworkSlavesUrl + priAddress);
  }

  getNetworkDataRecordsBySlavePriAddress(priAddress: number): Observable<INetworkDataRecord[]> {
    return this.http.get<INetworkDataRecord[]>(this.mbusNetworkSlavesUrl + priAddress + '/records');
  }

  getAllNetworkSlaves(): Observable<INetworkSlave[]> {
    return this.http.get<INetworkSlave[]>(this.mbusNetworkSlavesUrl);
  }

  startSearchSlave(): Observable<any> {
    return this.http.post<any>(this.mbusStartSearch, null);
  }

  stopSearchSlave(): Observable<any> {
    return this.http.post<any>(this.mbusStopSearch, null);
  }
}
