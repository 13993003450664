import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {MbusNetworkService} from "../core/services/mbus-network.service";
import {Subscription} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MeterService} from "../core/services/meter.service";
import {MessageService} from "primeng/api";
import {IMeterMapping, IUpdateMeterMappingDTO} from "../core/interfaces/mapping";
import {MappingService} from "../core/services/mapping.service";
import {WebSocketAPI} from "../core/services/WebSocketAPI";
import {IMBusSlave} from "../core/interfaces/MBusSlave";
import {MbusSlaveService} from "../core/services/mbus-slave.service";
import {IBacnetObject} from "../core/interfaces/bacnetObject";
import {LoggerService} from "../core/services/logger.service";
import {RxStompService} from "../core/services/rx-stomp.service";
import {Message} from "@stomp/stompjs";
import {ISearchTask, ISearchTaskQuery} from "../core/interfaces/task";
import {TaskService} from "../core/services/task.service";
import {ToastService} from "../core/services/toast.service";
import {EMBusConfState} from "../core/interfaces/app-enum";
import {StateService} from "../core/services/state.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-mbus-configurator',
  templateUrl: './mbus-configurator.component.html',
  styleUrls: ['./mbus-configurator.component.scss']
})
export class MbusConfiguratorComponent implements OnInit, OnDestroy {

  constructor(private stateService: StateService, private toastService: ToastService, private taskService: TaskService, private rxStompService: RxStompService, private webSocketAPI: WebSocketAPI, private loggerService: LoggerService, private mbusNetworkService: MbusNetworkService, private fb: FormBuilder, private meterService: MeterService, private messageService: MessageService, private mappingService: MappingService, private mBusSlaveService: MbusSlaveService) { }

  @Output()
  messageAdded: EventEmitter<string> = new EventEmitter<string>();

  // mbusNetworkSlaves: INetworkSlave[] = [];
  selectedSlaves: IMBusSlave[] = [];
  mBusSlaves: IMBusSlave[] = [];
  searchMetersDialogVisible: boolean = false;
  changeAddressDialogVisible: boolean = false;
  changeIdDialogVisible: boolean = false;
  changeBaudRateDialogVisible: boolean = false;
  assignBacnetObjectFormVisible: boolean = false;
  connectionTestFormVisible: boolean = false;
  searchActive: boolean = false;

  searchMetersForm!: FormGroup;
  changeAddressForm!: FormGroup;
  changeIdForm!: FormGroup;
  changeBaudRateForm!: FormGroup;
  assignBacnetObjectForm!: FormGroup;
  connectionForm!: FormGroup;

  bacnetMappings: IMeterMapping[] = [];
  selectedBacnetMapping: IMeterMapping | undefined;

  messages: string[] = [];

  baudRates: number[] = [300,2400,9600,38400];

  private state: EMBusConfState = EMBusConfState.IDLE;

  private setSearchState() {
    this.state = EMBusConfState.MBUS_SEARCH;
  }

  private setFreeState() {
    this.state = EMBusConfState.IDLE;
  }

  setMenuItems = [
    {
      label: 'Address',
      command: () => {this.changeAddress();}
    },
    {
      label: 'Id',
      command: () => {this.changeId();}
    },
    {
      label: 'Baud rate',
      command: () => {this.changeBaudRate();}
    }
  ]

  // counterTotalFound: number = 0;
  // counterDuplicateFound: number = 0;
  // meterFound$ = this.webSocketAPI.meterFoundAction$.pipe(
  //   tap(e=>{
  //     if (e.status == "FINISHED") {
  //       this.messageService.add({severity:'success', summary: 'Success', detail: 'Search finished, found ' + this.counterTotalFound + ' slaves, ' + this.counterDuplicateFound + ' duplicates'});
  //       this.counterDuplicateFound = 0;
  //       this.counterTotalFound = 0;
  //       this.messages.push("--Search finished--");
  //       console.log(e.message);
  //       this.searchMetersLabel = "Start search";
  //       this.searchActive = false;
  //       this.activeAction = false;
  //       this.webSocketAPI.disconnect();
  //       this.meterFoundSubscription.unsubscribe();
  //     } else if (e.status == "FOUND") {
  //       this.counterTotalFound++;
  //       console.log(e.message);
  //       if (e.mbusSlave) {
  //         if (!this.mBusSlaves.find(value => value.id == e.mbusSlave?.id)) {
  //           this.mBusSlaves.push(e.mbusSlave);
  //           this.mBusSlaves = [...this.mBusSlaves];
  //         } else {
  //           e.message = e.message + " - DUPLICATE";
  //           this.counterDuplicateFound++;
  //         }
  //       }
  //     } else if (e.status == "NOT_FOUND") {
  //       console.log(e.message);
  //     } else if (e.status == "SEARCH_START")
  //     {
  //       console.log(e.message);
  //     }
  //     this.loggerService.addMessage(e.message);
  //     this.messages.push(e.message);
  //   }),
  //   takeUntil(this.webSocketAPI.meterFoundAction$.pipe(filter(val => val == {} as IMbusSlaveSearchDTO))),
  //   finalize(() => {
  //     console.log("END");
  //     this.searchMetersLabel = "Start search";
  //     this.searchActive = false;
  //     this.activeAction = false;
  //     this.webSocketAPI.disconnect();
  //   }));
  meterFoundSubscription: Subscription = new Subscription;
  searchMetersLabel: string = "Search meters";
  activeAction: boolean = false;
  ngOnInit(): void {
    this.stateService.getState().subscribe(e => {
      this.state = e.state;
      this.onStateChange();
    })
    // this.rxStompService.watch('/topic/slave').subscribe((m: Message) => {
    //   console.log("FROM RX" + m.body);
    //   this.addSlaveToList(JSON.parse(m.body) as IMBusSlave);
    // });
    // this.rxStompService.watch('/topic/state').subscribe((m: Message) => {
    //   this.state = m.body as EMBusConfState;
    //   this.onStateChange();
    // });
    this.getSavedMBusSlaves();
    this.initializeSearchMetersForm();
    this.initializeChangeAddressForm();
    this.initializeChangeIdForm();
    this.initializeChangeBaudRateForm();
    this.initializeAssignBacnetObjectForm();
    this.initializeConnectionTestForm();
  }

  isSearchMode() {
    return EMBusConfState.MBUS_SEARCH == this.state;
  }

  isFreeMode() {
    return EMBusConfState.IDLE == this.state;
  }

  private addSlaveToList(slave: IMBusSlave) {
    if (slave) {
      if (!this.mBusSlaves.find(value => value.id == slave.id)) {
        this.mBusSlaves.push(slave);
        this.mBusSlaves = [...this.mBusSlaves];
      } else {

      }
    }
  }

  private getSavedMBusSlaves() {
    this.mBusSlaveService.getAllMBusSlaves().subscribe(e => {
      this.mBusSlaves = e;
    })
  }

  communicationTest() {
    this.connectionTestFormVisible = true;
    // from(this.selectedSlaves).pipe(
    //   concatMap(e => {
    //     e.status = "X";
    //     return this.mbusNetworkService.ge
    //   })
    // )
  }

  searchMeters() {
    switch (this.state) {
      case EMBusConfState.IDLE:
        this.searchMetersDialogVisible = true;
        break;
      case EMBusConfState.MBUS_SEARCH:
        this.stopSearch();
    }
  }

  initializeConnectionTestForm() {
    this.connectionForm = this.fb.group({
      method: 'PRI',
      baudRate: [2400, [Validators.required]]
    })
  }

  private initializeSearchMetersForm() {
    this.searchMetersForm = this.fb.group({
      erase: false,
      method: 'PRI',
      minRange: [0, [Validators.required, Validators.min(0),Validators.max(250)]] ,
      maxRange: [250, [Validators.required, Validators.min(0),Validators.max(250)]],
      baudRate: [2400, [Validators.required]],
    });
    this.searchMetersForm.get("method")?.valueChanges.subscribe(e => {
      this.onMethodChange(e);
    })
  }


  private initializeAssignBacnetObjectForm() {
    this.assignBacnetObjectForm = this.fb.group({

    })
  }

  private initializeChangeAddressForm() {
    this.changeAddressForm = this.fb.group({
      oldAddress: null,
      newAddress: [null,[Validators.required, Validators.min(0), Validators.max(250)]]
    });
    this.changeAddressForm.get('oldAddress')?.disable();
  }

  private initializeChangeIdForm() {
    this.changeIdForm = this.fb.group({
      oldId: null,
      newId: [null,[Validators.required, Validators.pattern("[0-9]{8}")]]
    });
    this.changeIdForm.get('oldId')?.disable();
  }

  private initializeChangeBaudRateForm() {
    this.changeBaudRateForm = this.fb.group({
      newBaudRate: [null, [Validators.required]]
    })
  }

  onSearchMetersSubmit() {
    let searchTask: ISearchTask = {} as ISearchTask;
    searchTask.id = 1; //TODO should be random generated
    searchTask.method = "search";
    searchTask.params = {} as ISearchTaskQuery;
    searchTask.params.method = this.searchMetersForm.get('method')?.value;
    searchTask.params.baudRate = this.searchMetersForm.get('baudRate')?.value;
    searchTask.params.minAddress = this.searchMetersForm.get('minRange')?.value;
    searchTask.params.maxAddress = this.searchMetersForm.get('maxRange')?.value;
    searchTask.params.erase = this.searchMetersForm.get('erase')?.value;

    this.taskService.createSearchTask(searchTask).subscribe(
      value => {
        if (this.searchMetersForm.get('erase')?.value) {
          this.mBusSlaves = [];
          this.mBusSlaves = [...this.mBusSlaves];
        }
        this.toastService.success("Search process started");
        this.searchMetersDialogVisible = false;
      }
    )
  }

  onMethodChange(method: string) {
    if ("PRI" == method) {
      this.searchMetersForm.get("minRange")?.setValidators([Validators.required, Validators.min(0),Validators.max(250)]);
      this.searchMetersForm.get("maxRange")?.setValidators([Validators.required, Validators.min(0),Validators.max(250)]);
    } else {
      this.searchMetersForm.get("minRange")?.clearValidators();
      this.searchMetersForm.get("minRange")?.setErrors(null);
      this.searchMetersForm.get("maxRange")?.clearValidators();
      this.searchMetersForm.get("maxRange")?.setErrors(null);
    }
    this.searchMetersForm.updateValueAndValidity();
  }

  hideSearchMetersDialog() {
    this.searchMetersDialogVisible = false;
  }

  hideChangeAddressDialog() {
    this.changeAddressDialogVisible = false;
  }

  changeAddress() {
    this.changeAddressDialogVisible = true;
    this.changeAddressForm.get('oldAddress')?.setValue(this.selectedSlaves[0]?.priAddress);
  }

  updateTableRow(networkSlave: IMBusSlave, old: IMBusSlave) {
    let tmp;
    tmp = this.mBusSlaves.find(m => m.priAddress == old.priAddress && m.secAddress == old.secAddress && m.medium == old.medium && m.version == old.version && m.manufacture == old.manufacture);
    if (tmp) {
      let index = this.mBusSlaves.indexOf(tmp);
      this.mBusSlaves[index] = networkSlave;
    }
    this.saveList();
  }

  changeId() {
    this.changeIdDialogVisible = true;
    this.changeIdForm.get('oldId')?.setValue(this.selectedSlaves[0]?.secAddress);
  }

  hideChangeIdDialog() {
    this.changeIdDialogVisible = false;
  }

  onAssignBacnetObjectSubmit() {
    let updateDTO = {} as IUpdateMeterMappingDTO;
    updateDTO.autoName = this.selectedBacnetMapping?.bacnetAutoName || true;
    updateDTO.objectName = this.selectedBacnetMapping?.bacnetObject?.name || " ";
    updateDTO.description = this.selectedBacnetMapping?.bacnetObject?.description || " ";
    updateDTO.objectLocation = this.selectedBacnetMapping?.bacnetObject?.location || " ";
    updateDTO.method = this.selectedBacnetMapping?.connMethod || "";
    updateDTO.address = this.selectedSlaves[0].method == "PRI" ? this.selectedSlaves[0].priAddress : this.selectedSlaves[0].secAddress || 0;
    updateDTO.baudRate = this.selectedSlaves[0].baudrate || 2400;
    updateDTO.interval = this.selectedSlaves[0].baudrate || 1440;
    this.mappingService.updateMapingRecord(this.selectedBacnetMapping?.recordNumber || 0, updateDTO).subscribe(next => {
      this.setBacnetObjects();
      this.assignBacnetObjectFormVisible = false;
    })
  }

  onChangeAddressSubmit() {
    this.mBusSlaveService.updatePriAddress(this.selectedSlaves[0].method, this.changeAddressForm.get('oldAddress')?.value, this.changeAddressForm.get('newAddress')?.value, this.selectedSlaves[0].id).subscribe(e => {
      this.updateTableRow(e, this.selectedSlaves[0]);
      this.changeAddressDialogVisible = false;
      this.messageService.add({severity:'success', summary: 'Success', detail: 'Address updated successfully'});
      this.selectedSlaves = [];
    })
  }

  onChangeIdSubmit() {
    this.mBusSlaveService.updateSecAddress("SEC", this.changeIdForm.get('newId')?.value, this.selectedSlaves[0].id).subscribe(e => {
      this.changeIdDialogVisible = false;
      this.updateTableRow(e, this.selectedSlaves[0]);
      this.changeAddressDialogVisible = false;
      this.messageService.add({severity:'success', summary: 'Success', detail: 'Address updated successfully'});
      this.selectedSlaves = [];
    })
  }

  onChangeBaudRateSubmit() {
    this.mBusSlaveService.updateBaudRateAddress("SEC", this.changeBaudRateForm.get('newBaudRate')?.value, this.selectedSlaves[0]?.id).subscribe(e => {
      this.changeBaudRateDialogVisible = false;
      this.updateTableRow(e, this.selectedSlaves[0]);
      this.changeBaudRateDialogVisible = false;
      this.messageService.add({severity:'success', summary: 'Success', detail: 'Baud rate updated successfully'});
      this.selectedSlaves = [];
    })
  }

  hideChangeBaudRateDialog() {
    this.changeBaudRateDialogVisible = false;
  }

  hideAssignBacnetObjectDialog() {
    this.assignBacnetObjectFormVisible = false;
  }

  changeBaudRate() {
    this.changeBaudRateDialogVisible = true;
  }

  saveList() {
    sessionStorage.setItem('slaves', JSON.stringify(this.mBusSlaves));
  }

  setBacnetObjects() {
    this.mappingService.getMappings().subscribe(e => {
      this.mBusSlaves.forEach(slave => {
        slave.bacnetObject = {} as IBacnetObject;
        let mapping = e.find(m => m.connMethod == slave.method && m.connBaudRate == slave.baudrate && (m.connMethod == "PRI" ? slave.priAddress : slave.secAddress) == m.connAddress);
        if (mapping) {
          slave.bacnetObject = mapping.bacnetObject;
        }
      })
      this.saveList();
    });
  }

  refreshBacnetObjects() {
    this.mBusSlaveService.refreshBacnetBindings().subscribe(e => {
      this.mBusSlaves = e;
    })
    // this.setBacnetObjects();
    // this.saveList();
    this.messageService.add({severity:'success', summary: 'Success', detail: 'BACnet objects refreshed'});
  }

  assignBacnetObject() {
    this.mappingService.getMappings().subscribe(e => {
      this.bacnetMappings = e.filter(e => e.bacnetObject && !e.connAddress);
      this.assignBacnetObjectFormVisible = true;
    })
  }

  onConnectionTestFormSubmit() {

  }

  hideConnectionTestDialog() {
    this.connectionTestFormVisible = false;
  }

  sendMessage() {
    this.webSocketAPI.send("test");
  }

  start() {
    this.meterService.startSearch().subscribe(e => {

    });
  }

  stopSearch() {
    this.meterFoundSubscription.unsubscribe();
    this.taskService.deleteSearchTask().subscribe(e => {
      this.toastService.success("Searching slaves stopped");
    })
  }

  deleteSlaves() {
    let ids: number[] = this.selectedSlaves.map(e => e.id);
    this.mBusSlaveService.deleteMBusSlavesByIds(this.selectedSlaves.map(e => e.id)).subscribe(
      e => {
        this.messageService.add({severity:'success', summary: 'Success', detail: 'Deleted selected slaves'});
        this.selectedSlaves = [];
        this.mBusSlaves = this.mBusSlaves.filter(e => !ids.includes(e.id));
      }
    )
  }

  ngOnDestroy(): void {
    this.meterFoundSubscription.unsubscribe();
  }

  private onStateChange() {
    switch (this.state) {
      case EMBusConfState.IDLE:
        this.searchMetersLabel = "Search meters"
        break;
      case EMBusConfState.MBUS_SEARCH:
        this.searchMetersLabel = "Stop search"
        break;
    }
  }

  isDuplicate(slave: any) {
    return this.mBusSlaves.filter(e => e.secAddress == slave.secAddress).length > 1;
  }
}
