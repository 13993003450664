import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {IAppState} from "../interfaces/app-enum";

@Injectable({
  providedIn: 'root'
})
export class StateService {

  baseUrl = environment.apiBaseUrl;
  stateUrl = this.baseUrl + 'api/state';

  constructor(private http: HttpClient) { }

  getState() {
    return this.http.get<IAppState>(this.stateUrl);
  }
}
