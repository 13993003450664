import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MbusConfiguratorRoutingModule} from "./mbus-configurator-routing.module";
import {ToolbarModule} from "primeng/toolbar";
import {MbusNetworkService} from "../core/services/mbus-network.service";
import {TableModule} from "primeng/table";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {DialogModule} from "primeng/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CheckboxModule} from "primeng/checkbox";
import {RadioButtonModule} from "primeng/radiobutton";
import {SliderModule} from "primeng/slider";
import {InputTextModule} from "primeng/inputtext";
import {InputNumberModule} from "primeng/inputnumber";
import {MeterService} from "../core/services/meter.service";
import {ToastModule} from "primeng/toast";
import {DropdownModule} from "primeng/dropdown";
import {SplitButtonModule} from "primeng/splitbutton";
import {TerminalModule, TerminalService} from "primeng/terminal";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {AngularSplitModule} from "angular-split";
import {WebSocketAPI} from "../core/services/WebSocketAPI";



@NgModule({
  declarations: [MbusConfiguratorRoutingModule.components],
  imports: [
    CommonModule,
    ToolbarModule,
    TableModule,
    ButtonModule,
    RippleModule,
    DialogModule,
    ReactiveFormsModule,
    CheckboxModule,
    RadioButtonModule,
    SliderModule,
    InputTextModule,
    InputNumberModule,
    ToastModule,
    FormsModule,
    DropdownModule,
    SplitButtonModule,
    TerminalModule,
    ScrollPanelModule,
    AngularSplitModule
  ],
  providers: [MbusNetworkService, MeterService, TerminalService, WebSocketAPI]
})
export class MbusConfiguratorModule { }
