import { Component, OnInit } from '@angular/core';
import {ObjectTreeService} from "../core/services/object-tree.service";
import {BACnetObjectTreeNode} from "../core/interfaces/object-tree";
import {TreeNode} from "primeng/api";
import {Router} from "@angular/router";
import {IBACnetObjectTypeAbbreviation} from "../core/interfaces/bacnet";

@Component({
  selector: 'app-object-tree',
  templateUrl: './object-tree.component.html',
  styleUrls: ['./object-tree.component.scss']
})
export class ObjectTreeComponent implements OnInit {

  constructor(private objectTreeService: ObjectTreeService, private router: Router) { }

  objects: BACnetObjectTreeNode[] = [];
  objectsTree: TreeNode<BACnetObjectTreeNode>[] = [];
  selectedNode: TreeNode<BACnetObjectTreeNode> = {} as TreeNode;

  ngOnInit(): void {
    this.objectsTree.push({
      data:
        {
          instance: 4,
          type: 1,
          name: "BLC-MBUS-Gateway",
          node_type: 0
        },
      label: "My Device",
      children: [],
      expanded: true
    })
    this.objectTreeService.getBacnetTreeNodes().subscribe(e => {
      this.buildTree(e);
    })
  }

  private buildTree(objects: BACnetObjectTreeNode[]) {
    //1. extract net objects
      objects.filter(o => o.node_type == 0).forEach(o => {
        let treeNode: TreeNode = {} as TreeNode;
        treeNode.data = o;
        treeNode.label = IBACnetObjectTypeAbbreviation[o.type] + o.instance + " " + o.name;
        treeNode.children = [];
        this.objectsTree[0].children?.push(treeNode);
      })
    //2. assign mbus meter objects
      objects.filter(o => o.node_type == 1).forEach(o => {
          let treeNode: TreeNode = {} as TreeNode;
          treeNode.data = o;
          treeNode.label = IBACnetObjectTypeAbbreviation[o.type] + o.instance + " " + o.name;
          treeNode.children = [];
          this.objectsTree[0].children?.find(n => n.data?.instance == 2)?.children?.push(treeNode);
        }
      )
    //3. assign mbus data record objects
    objects.filter(o => o.node_type == 2).forEach(o => {
        let treeNode: TreeNode = {} as TreeNode;
        treeNode.data = o;
        treeNode.label = IBACnetObjectTypeAbbreviation[o.type] + o.instance + " " + o.name;
        treeNode.children = [];
        this.objectsTree[0].children?.find(n => n.data?.instance == 2)?.children?.find(m => Math.trunc((o.instance)/1000) == m.data?.instance)?.children?.push(treeNode);
      }
    )
    //4. assign standard objects
    objects.filter(o => o.node_type == 3).forEach(o => {
        let treeNode: TreeNode = {} as TreeNode;
        treeNode.data = o;
        treeNode.label = IBACnetObjectTypeAbbreviation[o.type] + o.instance + " " + o.name;
        treeNode.children = [];
        this.objectsTree[0]?.children?.push(treeNode);
      }
    )
  }

  nodeSelect($event: any) {
    switch (this.selectedNode.data?.node_type) {
      case 0:
        if (this.selectedNode.data?.instance == 2) {
          this.router.navigate(['/meter-list']);
        }
        break;

      case 1:

        break;

      case 2:

        break;

      case 3:

        break;

      default:
        break;
    }
  }
}
