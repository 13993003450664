<!--<p-blockUI [blocked]="blockedDocument" [baseZIndex]="50000">-->
<!--</p-blockUI>-->
  <block-ui>
    <div style="display: flex; flex-direction: column; height: 100%">
        <p-accordion>
          <p-accordionTab header="Slave info" >
            <div class="formgrid grid">
              <div class= "col-12 md:col-6">
                <div class="field col-12">
                  <label for="firstname1">ID</label>
                  <input id="firstname1" [value]="mappingRecord.i" [disabled]="true" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <div class="field col-12">
                  <label for="firstname4">Address</label>
                  <input id="firstname4" [value]="mappingRecord.a" [disabled]="true" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <div class="field col-12">
                  <label for="baudrate">Baud rate</label>
                  <input id="baudrate" [value]="mappingRecord.b" [disabled]="true" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <div class="field col-12">
                  <label for="method">Baud rate</label>
                  <input id="method" [value]="IMBusMethod[mappingRecord.me]" [disabled]="true" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <div class="field col-12">
                  <label for="nke">NKE</label>
                  <input id="nke" [value]="mappingRecord.n" [disabled]="true" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <div class="field col-12">
                  <label for="rst">RST</label>
                  <input id="rst" [value]="mappingRecord.r" [disabled]="true" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <div class="field col-12">
                  <label for="model">Model</label>
                  <input id="model" [value]="mappingRecord.m" [disabled]="true" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <div class="field col-12">
                  <label for="status">Status</label>
                  <input id="status" [value]="mappingRecord.s" [disabled]="true" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
              </div>
              <div class= "col-12 md:col-6">
                <div class="field col-12">
                  <label for="firstname3">Meter Object</label>
                  <input id="firstname3" [value]="mappingRecord.bacnet_object_type" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <div class="field col-12">
                  <label for="firstname5">Name</label>
                  <input id="firstname5" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>

        <p-menubar [model]="items">
          <ng-template pTemplate="start">
            <button pButton pRipple type="button" pTooltip="Refresh slave records" (click)="refreshData()" [showDelay]="500" icon="pi pi-refresh" class="p-button-lg p-button-text p-button-plain"></button>
            <!--    <button pButton pRipple type="button" [disabled]="!isSingleSelection() || isSearchMode()" pTooltip="Edit Data Records" (click)="edit()" [showDelay]="500" icon="pi pi-pencil" class="p-button-lg p-button-text p-button-plain"></button>-->
            <!--    <button pButton pRipple type="button" [disabled]="true" pTooltip="Copy properties" [showDelay]="500" icon="pi pi-clone" class="p-button-lg p-button-text p-button-plain"></button>-->
            <!--    <button pButton pRipple type="button" [disabled]="true" pTooltip="Paste properties" [showDelay]="500" icon="pi pi-book" class="p-button-lg p-button-text p-button-plain"></button>-->
            <button pButton pRipple type="button" [disabled]="!isMultipleSelection()" pTooltip="Add BACnet object" (click)="openNewRecordDialog()" [showDelay]="500" icon="pi pi-plus" class="p-button-lg p-button-text p-button-plain"></button>
            <button pButton pRipple type="button" [disabled]="!isMultipleSelection()" pTooltip="Delete BACnet object" (click)="deleteMapping()" [showDelay]="500" icon="pi pi-trash" class="p-button-lg p-button-text p-button-plain"></button>
          </ng-template>
        </p-menubar>
      <div style="flex-grow: 1; height: 100%; overflow-y: auto">
        <p-table [value]="slaveDataRecords" [(selection)]="selectedSlaveDataRecords" [scrollable]="true" scrollHeight="flex" styleClass="p-datatable-sm" style="height: 100%">
          <ng-template pTemplate="header">
            <tr>
              <th rowspan="2" colspan="1"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
              <th colspan="9" style="background-color: #00b0f0">M-Bus response<i pTooltip="M-Bus meter data response" tooltipPosition="top" class="ml-1 pi pi-info-circle"></i></th>
              <th colspan="3" style="background-color: #d4d4d4">Calculation<i pTooltip="Calculation parameters" tooltipPosition="top" class="ml-1 pi pi-info-circle"></i></th>
              <th colspan="5" style="background-color: #92d050">BACnet<i pTooltip="BACnet object representing M-BUS data record" tooltipPosition="top" class="ml-1 pi pi-info-circle"></i></th>
            </tr>
            <tr>
              <th>No</th>
              <th>Type</th>
              <th>Value</th>
              <th>Unit</th>
              <th>Description</th>
              <th>Type</th>
              <th>M</th>
              <th>S</th>
              <th>T</th>
              <th>Mult</th>
              <th>Offset</th>
              <th>Value</th>
              <th>Object</th>
              <!--      <th style="background-color: #92D050"><span pTooltip="When true - object name dynamically created based on meter data, automatic change" tooltipPosition="top" [escape]="false">D</span></th>-->
              <th>Name</th>
              <th>Description</th>
              <th>Unit</th>
              <th>Trend</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data>
            <tr>
              <td>
                <p-tableCheckbox [value]="data"></p-tableCheckbox>
              </td>
              <td>{{data.record_no}}</td>
              <td>{{data.data_type}}</td>
              <td *ngIf="data.numeric">{{data.value}}</td>
              <td *ngIf="!data.numeric">{{data.value_str}}</td>
              <td>{{data.unit}}</td>
              <td>{{data.description}}</td>
              <td>{{data.type}}</td>
              <td>{{data.module < 0 ? 0 : data.module}}</td>
              <td>{{data.storage < 0 ? 0 : data.storage}}</td>
              <td>{{data.tariff < 0 ? 0 : data.tariff}}</td>
              <td>{{data.multiplier}}</td>
              <td>{{data.offset}}</td>
              <td></td>
              <td>{{IBACnetObjectTypeAbbreviation[data.bacnet_object_type]}}{{data.object_id}}</td>
              <td>{{data.object_name}}</td>
              <td>{{data.object_description}}</td>
              <td></td>
              <td>{{data.trend == 1 ? 'TL' + data.trend_object_instance : ''}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

<!--<p-toolbar>-->
<!--  <div class="p-toolbar-group-left">-->
<!--    <p-button label="Map to BACnet" icon="pi pi-plus" (click)="openNewRecordDialog()" styleClass="mr-2"></p-button>-->
<!--    <p-button label="Delete mapping" icon="pi pi-minus" (click)="deleteMapping()" styleClass="mr-2"></p-button>-->
<!--    <p-button label="Refresh data" icon="pi pi-refresh" (click)="refreshData()" styleClass="mr-2"></p-button>-->
<!--  </div>-->

<!--  <div class="p-toolbar-group-right">-->

<!--  </div>-->
<!--</p-toolbar>-->

<p-dialog [style]="{width: '50vw', height: '600px'}" header="Set calculation rules" [(visible)]="editFormVisible" [modal]="true">
  <form [formGroup]="editForm" (ngSubmit)="onEditSubmit()">
    <div class="formgrid grid mb-4">
      <div class="col-12 grid">
        <h3 style="color: #d4d4d4">Calculation</h3>
      </div>
      <div class="formgrid grid">
        <div class="col-12 field">
          <label for="multiplier" class="block">Multiplier</label>
          <p-inputNumber formControlName="multiplier" inputId="multiplier" id="multiplier"></p-inputNumber>
        </div>
        <div class="col-12 field">
          <label for="offset" class="block">Offset<span class="p-error">*</span></label>
          <p-inputNumber formControlName="offset" inputId="offset" id="offset"></p-inputNumber>
        </div>
        <div class="col-12 grid mt-2">
          <h3 style="color: #92D050">BACnet</h3>
        </div>
        <div class="col-12 field-checkbox">
          <p-checkbox formControlName="autoName" [binary]="true" id="autoName" inputId="autoName"></p-checkbox>
          <label for="autoName">Dynamic name - based on the record data</label>
        </div>
        <div class="col-12 field">
          <label for="name" class="block">Object name<span class="p-error">*</span></label>
          <input id="name" type="text" formControlName="name" pInputText />
          <small *ngIf="!editForm?.get('name')?.valid && editForm.get('name')?.enabled" id="username2-help" class="p-error block">Name is required</small>
        </div>
        <div class="col-12 field">
          <label for="description" class="block">Object description</label>
          <input id="description" type="text" formControlName="description" pInputText />
        </div>
        <div class="col-12 field">
          <label for="unit" class="block">
            Unit
            <span class="p-error">*</span>
          </label>
          <p-dropdown id="unit" inputId="unit" [options]="bacnetUnits" formControlName="unit">
            <ng-template let-unit pTemplate="selectedItem">
              <div>
                <div>{{unit}}</div>
              </div>
            </ng-template>
            <ng-template let-unit pTemplate="item">
              <div>
                <div>{{unit}}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-12 field-checkbox">
          <p-checkbox formControlName="trendLog" [binary]="true" id="trendLog" inputId="trendLog"></p-checkbox>
          <label for="trendLog">Log value</label>
        </div>
      </div>
      <p-button [disabled]="!editForm.valid" type="submit" class="mr-2" label="Submit"></p-button>
      <p-button label="Cancel" (click)="editFormHide()"></p-button>
    </div>

      </form>
    </p-dialog>

    <p-dialog header="Map M-Bus data record to BACnet object"
              [(visible)]="newRecordDialogVisible"
              [modal]="true"
              [style]="{width: '50vw', height: '600px'}">
      <form [formGroup]="newRecordForm" (ngSubmit)="onNewRecordSubmit()">
        <div class="formgrid grid mt-3">
          <!--      <div class="col-12 field">-->
          <!--        <label for="record_no" class="block">Record no</label>-->
          <!--        <input id="record_no" type="text" formControlName="record_no" pInputText />-->
          <!--      </div>-->
          <div class="col-12 field">
            <label for="multiplier" class="block">Multplier</label>
            <input id="multiplier" type="text" formControlName="multiplier" pInputText />
          </div>
          <div class="col-12 field">
            <label for="offset" class="block">Offset</label>
            <input id="offset" type="text" formControlName="offset" pInputText />
          </div>
          <!--      <div class="col-6 field">-->
          <!--        <label for="newMeterAddress" class="block">Meter address</label>-->
          <!--        <input id="newMeterAddress" type="text" formControlName="newMeterAddress" pInputText />-->
          <!--      </div>-->
          <div class="col-12 field">
            <label for="object_type" class="block">Object Type</label>
            <p-dropdown id="object_type" inputId="object_type" [options]="objectTypes" formControlName="object_type">
              <ng-template let-objectType pTemplate="selectedItem">
                <div>
                  <div>{{IBACnetObjectType[objectType]}}</div>
                </div>
              </ng-template>
              <ng-template let-objectType pTemplate="item">
                <div>
                  <div>{{IBACnetObjectType[objectType]}}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <!--        <small *ngIf="!newMeterForm?.get('baudRate')?.valid && newMeterForm?.get('baudRate')?.touched" id="invalidBaudRare" class="p-error block">Baud rate is required if address is not empty</small>-->
          </div>
          <div class="col-12 field-checkbox">
            <p-checkbox formControlName="trendlog" [binary]="true" id="trendlog" inputId="trendlog"></p-checkbox>
            <label for="trendlog">Trend Log</label>
          </div>
          <div class="col-12 field">
            <label for="interval" class="block">Interval</label>
            <input id="interval" type="text" formControlName="interval" pInputText />
          </div>
          <!--      <div class="col-12 field-checkbox">-->
          <!--        <p-checkbox formControlName="newMeterNKE" [binary]="true" id="newMeterNKE" inputId="newMeterNKE"></p-checkbox>-->
          <!--        <label for="newMeterNKE">SND_NKE</label>-->
          <!--      </div>-->
          <!--      <div class="col-12 field-checkbox">-->
          <!--        <p-checkbox formControlName="newMeterRST" [binary]="true" id="newMeterRST" inputId="newMeterRST"></p-checkbox>-->
          <!--        <label for="newMeterRST">SND_RST</label>-->
          <!--      </div>-->
        </div>
        <p-button [disabled]="!newRecordForm?.valid" type="submit" class="mr-2" label="Add"></p-button>
        <p-button label="Cancel" (click)="hideNewRecordDialog()"></p-button>
      </form>

    </p-dialog>
  </block-ui>

