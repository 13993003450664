export enum IBACnetObjectType {
   'ANALOG INPUT' = 0,
   'ANALOG OUTPUT' = 1,
   'ANALOG VALUE' = 2,
   'BINARY INPUT ' = 3,
   'BINARY OUTPUT ' = 4,
   'BINARY VALUE ' = 5,
   'CALENDAR ' = 6,
   'COMMAND ' = 7,
   'DEVICE ' = 8,
   'EVENT ENROLLMENT ' = 9,
   'FILE ' = 10,
   'GROUP ' = 11,
   'LOOP ' = 12,
   'MULTI STATE INPUT ' = 13,
   'MULTI STATE OUTPUT ' = 14,
   'NOTIFICATION CLASS ' = 15,
   'PROGRAM ' = 16,
   'SCHEDULE ' = 17,
   'AVERAGING ' = 18,
   'MULTI STATE VALUE' = 19,
  'TRENDLOG' = 20,
  'LIFE SAFETY POINT' = 21,
  'LIFE SAFETY ZONE' = 22,
  'ACCUMULATOR' = 23,
  'PULSE CONVERTER' = 24,
  'EVENT LOG' = 25,
  'GLOBAL GROUP' = 26,
  'TREND LOG MULTIPLE' = 27,
  'LOAD CONTROL' = 28,
  'STRUCTURED VIEW' = 29,
  'ACCESS DOOR' = 30,
  'TIMER' = 31,
  /* Note: 31 was lighting output, but BACnet editor changed it... */
  'ACCESS CREDENTIAL' = 32, /* Addendum 2008-j */
  'ACCESS POINT' = 33,
  'ACCESS RIGHTS' = 34,
  'ACCESS USER' = 35,
  'ACCESS ZONE' = 36,
  'CREDENTIAL DATA INPUT' = 37, /* authentication-factor-input */
  'NETWORK SECURITY' = 38, /* Addendum 2008-g */
  'BITSTRING VALUE' = 39, /* Addendum 2008-w */
  'CHARACTERSTRING VALUE' = 40, /* Addendum 2008-w */
  'DATE PATTERN VALUE' = 41, /* Addendum 2008-w */
  'DATE VALUE' = 42, /* Addendum 2008-w */
  'DATETIME PATTERN VALUE' = 43, /* Addendum 2008-w */
  'DATETIME VALUE' = 44, /* Addendum 2008-w */
  'INTEGER VALUE' = 45, /* Addendum 2008-w */
  'LARGE ANALOG VALUE' = 46, /* Addendum 2008-w */
  'OCTETSTRING VALUE' = 47, /* Addendum 2008-w */
  'POSITIVE INTEGER VALUE' = 48, /* Addendum 2008-w */
  'TIME PATTERN VALUE' = 49, /* Addendum 2008-w */
  'TIME VALUE' = 50, /* Addendum 2008-w */
  'NOTIFICATION FORWARDER' = 51, /* Addendum 2010-af */
  'ALERT ENROLLMENT' = 52, /* Addendum 2010-af */
  'CHANNEL' = 53, /* Addendum 2010-aa */
  'LIGHTING OUTPUT' = 54, /* Addendum 2010-i */
  'BINARY LIGHTING OUTPUT' = 55, /* Addendum 135-2012az */
  'NETWORK PORT' = 56, /* Addendum 135-2012az */
  'ELEVATOR GROUP' = 57, /* Addendum 135-2012aq */
  'ESCALATOR' = 58, /* Addendum 135-2012aq */
  'LIFT' = 59, /* Addendum 135-2012aq */
  'STAGING' = 60, /* Addendum 135-2016bd */
  'AUDIT LOG' = 61, /* Addendum 135-2016bi */
  'AUDIT REPORTER' = 62, /* Addendum 135-2016bi */
  'COLOR' = 63, /* Addendum 135-2020ca */
  'COLOR TEMPERATURE' = 64, /* Addendum 135-2020ca */
  'TYPE_LAST' = 'COLOR_TEMPERATURE',
  'BACNET_OBJECT_TYPE_RESERVED_MAX' = 127,
  /* Enumerated values 0-127 are reserved for definition by ASHRAE. */
  /* Enumerated values 128-1023 may be used by others subject to  */
  /* the procedures and constraints described in Clause 23. */
  'PROPRIETARY MIN' = 128,
  'PROPRIETARY MAX' = 1023,
  /* do the max range inside of enum so that
     compilers will allocate adequate sized datatype for enum
     which is used to store decoding */
  'MAX_BACNET_OBJECT_TYPE' = 1024,
  /* special usage for this library 16-bit max */
  'OBJECT_NONE' = 65535
}

export enum IBACnetObjectTypeAbbreviation {
  AI = 0,
  AO = 1,
  AV = 2,
  BI = 3,
  BO = 4,
  BV = 5,
  CAL = 6,
  CMD = 7,
  DEV = 8,
  EV = 9,
  FIL = 10,
  OBJECT_GROUP = 11,
  CO = 12,
  MSI = 13,
  MSO = 14,
  NC = 15,
  PG = 16,
  SCH = 17,
  AVG = 18,
  MSV = 19,
  TL = 20,
  LSP = 21,
  LSZ = 22,
  AC = 23,
  PC = 24,
  EVL = 25,
  GG = 26,
  TLM = 27,
  LS = 28,
  SV = 29,
  ACD = 30,
  TIM = 31,
  /* Note: 31 was lighting output, but BACnet editor changed it... */
  ACC = 32, /* Addendum 2008-j */
  ACP = 33,
  ACR = 34,
  ACU = 35,
  ACZ = 36,
  CDI = 37, /* authentication-factor-input */
  NS = 38, /* Addendum 2008-g */
  BSV = 39, /* Addendum 2008-w */
  CSV = 40, /* Addendum 2008-w */
  DPV = 41, /* Addendum 2008-w */
  DV = 42, /* Addendum 2008-w */
  DTP = 43, /* Addendum 2008-w */
  DTV = 44, /* Addendum 2008-w */
  IV = 45, /* Addendum 2008-w */
  LAV = 46, /* Addendum 2008-w */
  OSV = 47, /* Addendum 2008-w */
  PIV = 48, /* Addendum 2008-w */
  TPV = 49, /* Addendum 2008-w */
  TV = 50, /* Addendum 2008-w */
  NF = 51, /* Addendum 2010-af */
  AE = 52, /* Addendum 2010-af */
  CHL = 53, /* Addendum 2010-aa */
  LO = 54, /* Addendum 2010-i */
  BLO = 55, /* Addendum 135-2012az */
  NET = 56, /* Addendum 135-2012az */
  EL = 57, /* Addendum 135-2012aq */
  ESC = 58, /* Addendum 135-2012aq */
  LFT = 59, /* Addendum 135-2012aq */
  STG = 60, /* Addendum 135-2016bd */
  AUL = 61, /* Addendum 135-2016bi */
  AUR = 62, /* Addendum 135-2016bi */
  CL = 63, /* Addendum 135-2020ca */
  CLT = 64, /* Addendum 135-2020ca */
  BACNET_OBJECT_TYPE_RESERVED_MAX = 127,
  /* Enumerated values 0-127 are reserved for definition by ASHRAE. */
  /* Enumerated values 128-1023 may be used by others subject to  */
  /* the procedures and constraints described in Clause 23. */
  OBJECT_PROPRIETARY_MIN = 128,
  OBJECT_PROPRIETARY_MAX = 1023,
  /* do the max range inside of enum so that
     compilers will allocate adequate sized datatype for enum
     which is used to store decoding */
  MAX_BACNET_OBJECT_TYPE = 1024,
  /* special usage for this library 16-bit max */
  UKN = 65535
}
