import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {LoggerService} from "./core/services/logger.service";
import {RxStompService} from "./core/services/rx-stomp.service";
import {Message} from "@stomp/stompjs";
import {TerminalService} from "primeng/terminal";
import {WebsocketService} from "./core/services/websocket.service";
import {catchError, concatMap, EMPTY, interval} from "rxjs";
import {StatsService} from "./core/services/stats.service";
import {IMemoryStats} from "./core/interfaces/general";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(public datepipe: DatePipe, private rxStompService: RxStompService, private loggerService: LoggerService, private terminalService: TerminalService, private websocketService: WebsocketService, private statsService: StatsService) {
    loggerService.messageAction$.subscribe(e => {
      e = this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss ') + e;
      this.messages.unshift(e);
    })
  }

  title = 'blc-mbus-configurator';
  items: MenuItem[] = [];
  messages: string[] = [];
  currMemoryStats: IMemoryStats | undefined;
  ngOnInit(): void {
    interval(5000).pipe(concatMap(e=> this.statsService.getMemoryStats().pipe(catchError(() => EMPTY)))).subscribe({
      next: (e) => {
        this.currMemoryStats = e;
      },
      error: (err) => {

      },
      complete: () => {}});
    // this.websocketService.messages.subscribe(msg => {
    //     // this.received.push(msg);
    //     // console.log("Response from websocket: " + msg);
    //     this.messages.unshift(msg.data.message);
    //   });
    // this.rxStompService.watch('/topic/meters').subscribe(m => {
    //   this.messages.push(JSON.parse(m.body).message);
    // });
    // this.rxStompService.watch('/topic/test').subscribe(m => {
    //   this.messages.push(JSON.parse(m.body).message);
    // });
    // this.rxStompService.watch('/topic/message').subscribe((m: Message) => {
    //   this.messages.unshift(m.body);
    // });
    // this.rxStompService.watch('/topic/state').subscribe((m: Message) => {
    //   console.log("FROM RX" + m.body);
    // });
    // this.loggerService.messageAction$.subscribe(e => {
    //   this.messages.push(e);
    // })
    this.items = [
      // {
      //   label: 'Gateway',
      //   icon: 'pi pi-calculator\n',
      //   routerLink: 'navigator/meters'
      // },
      // {
      //   label: 'M-Bus',
      //   icon: 'pi pi-sitemap',
      //   routerLink: 'mbus-configurator'
      // }
    ];
  }

}
