import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {
  IBACnetMBusDataMappingRecord, IBACnetMBusMappingConnectionProp, IBACnetMBusMappingPasteConf,
  IBACnetMBusMappingRecord, IBACnetMBusMappingRecordNew,
  ICopyMeterMappingDTO, IMBusDataRec, IMBusDataRecordNew, IMBusMethod,
  IMeterMapping,
  IUpdateMeterMappingDTO
} from "../interfaces/mapping";
import {IMBusDataRecord} from "../interfaces/MBusSlave";
import {INetworkSlave} from "../interfaces/mbus-network-interfaces";
import {EMBusMethod} from "../interfaces/mbus";

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  baseUrl = environment.apiBaseUrl;
  mappingsUrl = this.baseUrl + 'api/mappings';
  recordsUrl = this.baseUrl + 'api/records';
  testMappingUrl = this.mappingsUrl + '/test'
  createBacnetObjectUrl = this.mappingsUrl + '/bacnetObject';
  createObjectsUrl = this.mappingsUrl + '/objects';

  constructor(private http: HttpClient) { }

  testSlave(mbus_address: number, mbus_id: number, method: IMBusMethod): Observable<IBACnetMBusMappingRecord> {
    let params = new HttpParams();
    params = params.append('address', mbus_address);
    params = params.append('id', mbus_id);
    params = params.append('method', method);
    return this.http.get<IBACnetMBusMappingRecord>(this.testMappingUrl, {params: params});
  }

  deleteBACnetObjectForMappingRecord(mbus_id: number): Observable<IBACnetMBusMappingRecord> {
    let params = new HttpParams();
    params = params.append('id', mbus_id);
    return this.http.delete<IBACnetMBusMappingRecord>(this.createBacnetObjectUrl, {params: params});
  }

  createBACnetObjectForMappingRecord(mbus_id: number): Observable<IBACnetMBusMappingRecord> {
    let params = new HttpParams();
    params = params.append('id', mbus_id);
    return this.http.post<IBACnetMBusMappingRecord>(this.createBacnetObjectUrl, null,{params: params});
  }

  addMbusDataRecord(newDataRecordDTO: IMBusDataRecordNew): Observable<IBACnetMBusDataMappingRecord> {
    return this.http.post<IBACnetMBusDataMappingRecord>(this.recordsUrl, newDataRecordDTO);
  }

  deleteMBusDataMapping(method: IMBusMethod, address: number, record_no: number) {
    let params = new HttpParams();
    params = params.append('address', address);
    params = params.append('method', method);
    params = params.append('no', record_no);
    return this.http.delete(this.recordsUrl, {params: params})
  }

  getBacnetMBusMappingRecords(): Observable<IBACnetMBusMappingRecord[]> {
    return this.http.get<IBACnetMBusMappingRecord[]>(this.mappingsUrl);
  }

  getMBusDataRecords(mapping_id: number, mbus_address: number, method: IMBusMethod): Observable<IMBusDataRec[]> {
    let params = new HttpParams();
    params = params.append('id', mapping_id);
    params = params.append('address', mbus_address);
    params = params.append('method', method);
    return this.http.get<IMBusDataRec[]>(this.recordsUrl, {params: params})
  }

  getBacnetMBusMappingRecord(id: number): Observable<IBACnetMBusMappingRecord> {
    return this.http.get<IBACnetMBusMappingRecord>(this.mappingsUrl + '/' + id);
  }

  addBacnetMBusMappingRecord(newRecordDTO: IBACnetMBusMappingRecordNew): Observable<IBACnetMBusMappingRecord> {
    return this.http.post<IBACnetMBusMappingRecord>(this.mappingsUrl, newRecordDTO);
  }

  deleteBacnetMBusMappingRecord(mbus_id: number) {
    let params = new HttpParams();
    params = params.append('id', mbus_id);
    return this.http.delete(this.mappingsUrl, {params: params});
  }

  updateBacnetMBusMappingConnectionProperties(mbus_id: number, conn_props: IBACnetMBusMappingConnectionProp): Observable<IBACnetMBusMappingRecord> {
    return this.http.post<IBACnetMBusMappingRecord>(this.mappingsUrl + '/' + mbus_id + '/connection', conn_props);
  }

  pasteMappingConfiguration(mbus_id: number, conn_props: IBACnetMBusMappingPasteConf): Observable<IBACnetMBusMappingRecord> {
    return this.http.post<IBACnetMBusMappingRecord>(this.mappingsUrl + '/' + mbus_id + '/paste', conn_props);
  }

  getMappings(): Observable<IMeterMapping[]> {
    return this.http.get<IMeterMapping[]>(this.mappingsUrl);
  }

  communicationTest(recordNumber: number): Observable<IMeterMapping> {
    return this.http.get<IMeterMapping>(this.mappingsUrl + recordNumber + "/communicationTest");
  }

  createMapping(mapping: IMeterMapping) {
    return this.http.post<IMeterMapping>(this.mappingsUrl, mapping);
  }

  createObjectForMappingRecords(recordNumbers: number[]) {
    let params = new HttpParams();
    params = params.append('recordNumbers',recordNumbers.toString());
    return this.http.post<IMeterMapping[]>(this.createObjectsUrl, null, {params: params});
  }

  deleteObjectsForMappingRecords(recordNumbers: number[]) {
    let params = new HttpParams();
    params = params.append('recordNumbers',recordNumbers.toString());
    return this.http.delete<IMeterMapping[]>(this.createObjectsUrl,{params: params});
  }

  updateMapingRecord(recordNumber: number, updateDTO: IUpdateMeterMappingDTO) {
    return this.http.patch<IMeterMapping>(this.mappingsUrl + recordNumber, updateDTO);
  }

  copyMappingRecords(recordNumber: number, recordNumbersToPaste: number[], copyDTO: ICopyMeterMappingDTO) {
    let params = new HttpParams();
    params = params.append('recordNumbersToPaste',recordNumbersToPaste.toString());

    return this.http.post<IMeterMapping[]>(this.mappingsUrl + recordNumber + "/copy", copyDTO, {params: params});
  }

  getDataRecords(mappingId: number): Observable<IMBusDataRecord[]> {
    return this.http.get<IMBusDataRecord[]>(this.mappingsUrl + mappingId + "/dataRecords");
  }

  getDataRecordsFromMBus(mappingId: number): Observable<IMBusDataRecord[]> {
    return this.http.get<IMBusDataRecord[]>(this.mappingsUrl + mappingId + "/dataRecords/mbus");
  }

  // getMappingByMethodAndAddress(method: IMBusMethod, address: any): Observable<IBACnetMBusMappingRecord> {
  //     // return this.ma
  // }
}
