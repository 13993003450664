import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {IMBusSlave} from "../interfaces/MBusSlave";
import {INetworkSlave} from "../interfaces/mbus-network-interfaces";
import {IBACnetMBusMappingRecord, ISlaveChangeAddress, ISlaveChangeId} from "../interfaces/mapping";

@Injectable({
  providedIn: 'root'
})
export class MbusSlaveService {

  baseUrl = environment.apiBaseUrl;
  mbusUrl = this.baseUrl + 'api/mbus-slaves/'
  slaveUrl = this.baseUrl + 'api/slave';
  changeIdUrl = this.slaveUrl + '/id';
  changeAddressUrl = this.slaveUrl + '/address';
  refreshBacnetBindingsUrl = this.mbusUrl + 'refreshBacnetObjects';
  constructor(private http: HttpClient) { }

  getAllMBusSlaves(): Observable<IMBusSlave[]> {
    return this.http.get<IMBusSlave[]>(this.mbusUrl);
  }

  refreshBacnetBindings(): Observable<IMBusSlave[]> {
    return this.http.get<IMBusSlave[]>(this.refreshBacnetBindingsUrl);
  }

  deleteMBusSlavesByIds(ids: number[]): Observable<any> {
    let params = new HttpParams();
    params = params.append('ids',ids.toString());
    return this.http.delete(this.mbusUrl, {params: params});
  }

  updatePriAddress(method: string, oldPriAddress: number, newPriAddress: number, id: number) {
    let params = new HttpParams();
    params = params.append('method',method);
    params = params.append('oldPriAddress', oldPriAddress);
    params = params.append('newPriAddress',newPriAddress);
    return this.http.put<IMBusSlave>(this.mbusUrl + id + "/priAddress", null, {params: params});
  }

  updateSecAddress(method: string, newSecAddress: number, id: number) {
    let params = new HttpParams();
    params = params.append('method',method);
    params = params.append('newSecAddress',newSecAddress);
    return this.http.put<IMBusSlave>(this.mbusUrl + id + "/secAddress", null,{params: params});
  }

  updateBaudRateAddress(method: string, newBaudRate: number, id: number) {
    let params = new HttpParams();
    params = params.append('method',method);
    params = params.append('newBaudRate',newBaudRate);
    return this.http.put<IMBusSlave>(this.mbusUrl + id + "/baudRate", null, {params: params});
  }

  changeId(changeIDDto: ISlaveChangeId): Observable<IBACnetMBusMappingRecord> {
    return this.http.post<IBACnetMBusMappingRecord>(this.changeIdUrl, changeIDDto);
  }

  changeAddress(changeAddressDto: ISlaveChangeAddress): Observable<IBACnetMBusMappingRecord> {
    return this.http.post<IBACnetMBusMappingRecord>(this.changeAddressUrl, changeAddressDto);
  }
}
