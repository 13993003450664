import { NgModule } from '@angular/core';
import {NavigatorComponent} from "./navigator.component";
import {RouterModule} from "@angular/router";
import {MeterTableComponent} from "./meter-table/meter-table.component";
import {DataTableComponent} from "./data-table/data-table.component";

const routes = [
  {
    path: 'navigator',
    component: NavigatorComponent,
    children: [
      {
        path: 'meters',
        component: MeterTableComponent
      },
      {
        path: 'data',
        component: DataTableComponent
      }]
  }
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class NavigatorRoutingModule {
  static components = [NavigatorComponent]
}
