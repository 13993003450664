import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MappingService} from "../../core/services/mapping.service";
import {IMBusDataRecord} from "../../core/interfaces/MBusSlave";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AppDataService} from "../../core/services/app-data.service";
import {MbusDataRecordService} from "../../core/services/mbus-data-record.service";
import {
  IBACnetMBusMappingRecord,
  IMBusDataRec,
  IMBusDataRecordNew,
  IMBusMethod,
  ISlaveDataRecord,
  IUpdateDataRecordDTO
} from "../../core/interfaces/mapping";
import {ToastService} from "../../core/services/toast.service";
import {IBACnetObjectType, IBACnetObjectTypeAbbreviation} from "../../core/interfaces/bacnet";
import {MenuItem} from "primeng/api";
import {concatMap, from} from "rxjs";
import {EError} from "../../core/interfaces/app-enum";
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  //new record dialog
  newRecordDialogVisible: boolean = false;
  newRecordForm!: FormGroup;
  mapping_record!: IBACnetMBusMappingRecord;
  dataRecords: IMBusDataRec[] = [];

  constructor(private toastService: ToastService, private mbusDataRecordService: MbusDataRecordService, private router: Router, private appDataService: AppDataService, private mappingService: MappingService, private route: ActivatedRoute, private fb: FormBuilder) {
    this.refreshData();
    // this.blockUI.start('Loading...');
  }

  blockUIStart() {
    this.blockUI.start('Loading...');
  }

  blockUIStop() {
    this.blockUI.stop();
  }

  mappingRecord: IBACnetMBusMappingRecord = {} as IBACnetMBusMappingRecord;


  mBusDataRecords: IMBusDataRecord[] = [];
  slaveDataRecords: ISlaveDataRecord[] = [];
  selectedSlaveDataRecords: ISlaveDataRecord[] = [];
  selectedMBusDataRecords: IMBusDataRecord[] = [];

  //query params
  address: number = 0;
  id: number = 0;
  method: IMBusMethod = IMBusMethod.PRI;

  mappingId: number = 1;

  //forms
  editForm!: FormGroup;
  editFormVisible: boolean = false;

  bacnetUnits: string[] = this.appDataService.getBacnetUnits();

  ngOnInit(): void {
    this.initForms();



    // this.route.queryParams.subscribe(params => {
    //   this.mappingId = params['mapping'];
    //   this.mappingService.getDataRecords(params['mapping']).subscribe(e => {
    //     if (e.length != 0) {
    //       this.mBusDataRecords = e;
    //     } else {
    //       this.mappingService.getDataRecordsFromMBus(params['mapping']).subscribe(e => {
    //         this.mBusDataRecords = e;
    //       })
    //     }
    //   })
    // })
  }

  private initForms() {
    this.editForm = this.fb.group({
      multiplier: null,
      offset: null,
      autoName: true,
      name: ['', Validators.required],
      description: '',
      unit: '',
      trendLog: false
    })
    this.editForm.get("autoName")?.valueChanges.subscribe(e => {
      this.validateName(e);
    })
    this.newRecordForm = this.fb.group({
      record_no: 0,
      multiplier: 1,
      offset: 0,
      object_type: IBACnetObjectType["ANALOG VALUE"],
      interval: 30,
      trendlog: false
    });
  }

  meters() {
    this.router.navigate(["/navigator/meters"]);
  }

  refreshMBus() {
    this.route.queryParams.subscribe(params => {
      this.mappingService.getDataRecordsFromMBus(params['mapping']).subscribe(e => {
        this.mBusDataRecords = e;
      });
    });
  }

  editFormShow() {
    this.editForm.setValue({
      multiplier: this.selectedMBusDataRecords[0].calcMultiplier,
      offset: this.selectedMBusDataRecords[0].calcOffser,
      autoName: this.selectedMBusDataRecords[0].bacnetAutoName,
      name: this.selectedMBusDataRecords[0].bacnetObject.name,
      description: this.selectedMBusDataRecords[0].bacnetObject.description,
      unit: '',
      trendLog: this.selectedMBusDataRecords[0].tlActive
    })
    this.editFormVisible = true;
  }

  editFormHide() {
    this.editFormVisible = false
    this.editForm.reset();
  }

  onEditSubmit() {
    if (this.editForm.valid) {
      let updateDTO = {} as IUpdateDataRecordDTO;
      updateDTO.multiplier = this.editForm.get("multiplier")?.value;
      updateDTO.offset = this.editForm.get("offset")?.value;
      updateDTO.autoName = this.editForm.get("autoName")?.value;
      updateDTO.objectName = this.editForm.get("name")?.value;
      updateDTO.description = this.editForm.get("description")?.value;
      updateDTO.unit = this.editForm.get("unit")?.value;
      updateDTO.logValue = this.editForm.get("trendLog")?.value;
      this.mbusDataRecordService.updateDataRecord(this.selectedMBusDataRecords[0].id, updateDTO).subscribe(
        next => {
          this.editFormHide();
          this.selectedMBusDataRecords = [];
          this.toastService.success("Record updated");
          this.updateTableRow(next);
          console.log(next);
        }
      )
      this.editForm.reset();
    }
  }

  updateTableRow(dataRecord: IMBusDataRecord) {
    let tmp;
    tmp = this.mBusDataRecords.find(r => r.id == dataRecord.id);
    if (tmp) {
      let index = this.mBusDataRecords.indexOf(tmp);
      this.mBusDataRecords[index]=dataRecord;
    }
  }

  createBacnetObjects() {
    let ids: number[] = this.selectedMBusDataRecords.map(r => r.id);
    this.mbusDataRecordService.createObjectForMappingRecords(ids).subscribe(next => {
      next.forEach(data => {
        this.updateTableRow(data);
      })
      this.selectedMBusDataRecords = [];
      this.toastService.success('BACnet objects created for ' + next.length + ' records')
    })
  }

  deleteBacnetObjects() {
    let ids: number[] = this.selectedMBusDataRecords.map(r => r.id);
    this.mbusDataRecordService.deleteObjectsForMappingRecords(ids).subscribe(next => {
      next.forEach(data => {
        this.updateTableRow(data);
      })
      this.selectedMBusDataRecords = [];
      this.toastService.success('BACnet objects deleted for ' + next.length + ' records')
    })
  }

  private validateName(autoName: boolean) {
    if (autoName) {
      this.editForm.get("name")?.removeValidators(Validators.required);
      this.editForm.get("name")?.setValue(this.createAutoName(this.selectedMBusDataRecords[0]));
      this.editForm.get("name")?.disable();
    } else {
      this.editForm.get("name")?.addValidators(Validators.required);
      this.editForm.get("name")?.enable();
    }
    this.editForm.get("name")?.updateValueAndValidity();
  }

  createAutoName(record: IMBusDataRecord): string {
      return record.mbusDescription + " (M" + String(record.meterMapping.recordNumber).padStart(3,'0') + "R" + String(record.mbusRecNo).padStart(3,'0') + ")";
  }

  selectedOneRecordWithBACNetObjectCreated(): boolean {
    return (this.selectedMBusDataRecords.length == 1 && this.selectedMBusDataRecords[0]?.bacnetObject != null);
  }

  openNewRecordDialog() {
    this.newRecordDialogVisible = true;
  }

  hideNewRecordDialog() {
    this.newRecordDialogVisible = false;
  }

  protected readonly IMBusMethod = IMBusMethod;

  onNewRecordSubmit() {
    let newDataRecordDTO = {} as IMBusDataRecordNew;
    newDataRecordDTO.mult = Number(this.newRecordForm.get("multiplier")?.value);
    newDataRecordDTO.offset = Number(this.newRecordForm.get("offset")?.value);
    newDataRecordDTO.object_type = Number(this.newRecordForm.get("object_type")?.value);
    newDataRecordDTO.interval = Number(this.newRecordForm.get("interval")?.value);
    newDataRecordDTO.trendlog = this.newRecordForm.get("trendlog")?.value ? 1 : 0;
    newDataRecordDTO.mbus_addr = Number(this.address);
    newDataRecordDTO.mbus_method = Number(this.method);
    from(this.selectedSlaveDataRecords).pipe(
      concatMap(ev => {
        newDataRecordDTO.record_no = ev.record_no;
        return this.mappingService.addMbusDataRecord(newDataRecordDTO)
      })
    ).subscribe({
      next: (e) => {
        this.toastService.success("BACnet object created");
        // this.updateTableRow(e);
      },
      error: (err) => {
        this.toastService.error(EError[err.error.error]);
      },
      complete: () => {
        this.blockUIStop();
        this.selectedSlaveDataRecords = [];
        this.hideNewRecordDialog();
        this.refreshData();
      }
    })
    // let newDataRecordDTO = {} as IMBusDataRecordNew;
    // newDataRecordDTO.record_no = this.selectedSlaveDataRecords[0].record_no;
    // newDataRecordDTO.mult = Number(this.newRecordForm.get("multiplier")?.value);
    // newDataRecordDTO.offset = Number(this.newRecordForm.get("offset")?.value);
    // newDataRecordDTO.object_type = Number(this.newRecordForm.get("object_type")?.value);
    // newDataRecordDTO.interval = Number(this.newRecordForm.get("interval")?.value);
    // newDataRecordDTO.trendlog = this.newRecordForm.get("trendlog")?.value ? 1 : 0;
    // newDataRecordDTO.mbus_addr = Number(this.address);
    // newDataRecordDTO.mbus_method = Number(this.method);
    // this.mappingService.addMbusDataRecord(newDataRecordDTO).subscribe(e => {
    //   this.selectedSlaveDataRecords[0].multiplier = e.mult;
    //   this.selectedSlaveDataRecords[0].offset = e.offset;
    //   this.selectedSlaveDataRecords[0].object_name = e.obj.name;
    //   this.selectedSlaveDataRecords[0].object_description = e.obj.description;
    //   this.selectedSlaveDataRecords[0].bacnet_object_type = e.bacnet_object_type;
    //   this.selectedSlaveDataRecords[0].object_id = e.obj.instance;
    //   this.selectedSlaveDataRecords[0].object_unit = String(e.obj.unit);
    //   this.selectedSlaveDataRecords[0].trend = e.trend
    //   this.selectedSlaveDataRecords[0].trend_object_instance = e.trend_object_instance;
    //   // this.mBusDataRecords.push(e);
    //   this.toastService.success("Added record successfully");
    //   this.hideNewRecordDialog();
    // })
  }

  private sumRecords() {
    console.log("TADAM");

    this.dataRecords.forEach(r => {
      let slaveDataRecord = {} as ISlaveDataRecord;
      slaveDataRecord.record_no = r.r;
      slaveDataRecord.data_type = "TODO";
      slaveDataRecord.value = r.v;
      slaveDataRecord.value_str = r.vs;
      slaveDataRecord.numeric = r.n == 1;
      slaveDataRecord.unit = r.u;
      slaveDataRecord.description = r.d;
      slaveDataRecord.type = r.t;
      slaveDataRecord.storage = r.s;
      slaveDataRecord.module = r.m;
      slaveDataRecord.tariff = r.ta;
      this.slaveDataRecords.push(slaveDataRecord);
    });
    console.log(this.slaveDataRecords);
    this.mappingRecord.d.forEach(r => {
      let slave = this.slaveDataRecords.find(s => s.record_no == r.n);
      if (slave) {
        slave.offset = r.of;
        slave.multiplier = r.m;
        slave.object_id = r.o?.i;
        slave.object_name = r.o?.na;
        slave.object_description = r.o?.d;
        slave.bacnet_object_type = r.b;
        // slave.obj = r.obj;
      } else {
        let slaveDataRecord = {} as ISlaveDataRecord;
        slaveDataRecord.record_no = r.n;
        slaveDataRecord.offset = r.of;
        slaveDataRecord.multiplier = r.m;
        slaveDataRecord.object_id = r.o?.i;
        slaveDataRecord.bacnet_object_type = r.b;
        slaveDataRecord.object_name = r.o?.na;
        slaveDataRecord.object_description = r.o?.d;
        this.slaveDataRecords.push(slaveDataRecord);
      }
    });
  }

  IBACnetObjectType = IBACnetObjectType;
  IBACnetObjectTypeAbbreviation = IBACnetObjectTypeAbbreviation;
  objectTypes: IBACnetObjectType[] = [IBACnetObjectType["ANALOG VALUE"], IBACnetObjectType["LARGE ANALOG VALUE"], IBACnetObjectType["CHARACTERSTRING VALUE"]];
  items: MenuItem[] = [];
  // blockedDocument: boolean = true;

  deleteMapping() {
    this.blockUIStart();
    from(this.selectedSlaveDataRecords).pipe(
      concatMap(ev => {
        return this.mappingService.deleteMBusDataMapping(this.mappingRecord.me, this.mappingRecord.me == IMBusMethod.PRI ? this.mappingRecord.a : this.mappingRecord.i, ev.record_no)
      })
    ).subscribe({
      next: (e) => {
        this.toastService.success("Data record mapping deleted");
      },
      error: (err) => {
        this.toastService.error(EError[err.error.error]);
      },
      complete: () => {
        this.blockUIStop();
        this.selectedSlaveDataRecords = [];
        this.refreshData();
      }
    })


    // this.mappingService.deleteMBusDataMapping(this.mappingRecord.me, this.mappingRecord.me == IMBusMethod.PRI ? this.mappingRecord.a : this.mappingRecord.i, this.selectedSlaveDataRecords[0].record_no).subscribe(
    //   e => {
    //   delete this.selectedSlaveDataRecords[0].multiplier;
    //   delete this.selectedSlaveDataRecords[0].offset;
    //   this.selectedSlaveDataRecords[0].object_name = '';
    //   this.selectedSlaveDataRecords[0].object_description = '';
    //   delete this.selectedSlaveDataRecords[0].object_id;
    //   this.selectedSlaveDataRecords[0].object_unit = '';
    //   this.toastService.success("Data record mapping deleted");
    // },
    // error => {
    //   this.toastService.error("Data record mapping not deleted");
    // })
  }

  isSingleSelection(): boolean {
    return this.selectedSlaveDataRecords.length == 1;
  }

  isMultipleSelection(): boolean {
    return this.selectedSlaveDataRecords.length > 0;
  }

  refreshData() {
    this.blockUIStart();
    this.slaveDataRecords = [];
    this.route.queryParams.subscribe(params => {
      this.address = params['address'];
      this.id = params['id'];
      this.method = params['method'];
      this.mappingService.getBacnetMBusMappingRecord(this.id).subscribe(e => {
          this.mappingRecord = e;
          this.mappingService.getMBusDataRecords(this.id, this.address, this.method).subscribe({
            next: (e) => {
              this.dataRecords = e;
              this.sumRecords();
            },
            error: (error) => {console.log(error)},
            complete: () => {
              this.blockUIStop();
            }
          })
        },
        error => {
          this.blockUIStop();
          console.log(error)
        },
        () => {
        });
    });
  }

  protected readonly String = String;
}
