<p-toolbar>
  <div class="p-toolbar-group-left">
    <button pButton pRipple type="button" label="Create BACnet Objects" class="p-button-outlined p-button-success mr-2" (click)="createBACnetObjectsForRecords()" [disabled]="selectedMappings.length < 1"></button>
    <button pButton pRipple type="button" label="Delete BACnet Objects" class="p-button-outlined p-button-danger mr-2" (click)="deleteBACnetObjectsForRecords()" [disabled]="selectedRecordsWithBACnetObjectCount() < 1"></button>
    <button pButton pRipple type="button" label="Connection Test" class="p-button-outlined p-button-info mr-2" (click)="communicationTest()" [disabled]="selectedMappings.length < 1"></button>
    <button pButton pRipple type="button" label="Copy" class="p-button-outlined p-button-info mr-2" (click)="copyRecord()" [disabled]="!selectedOneRecordWithBACNetObjectCreated()"></button>
    <button pButton pRipple type="button" label="Paste" class="p-button-outlined p-button-info mr-2" (click)="pasteRecords()" [disabled]="selectedMappings.length < 1 || !copiedMapping"></button>
    <button pButton pRipple type="button" label="Edit" class="p-button-outlined p-button-info mr-2" (click)="editRecord()" [disabled]="!selectedOneRecordWithBACNetObjectCreated()"></button>
    <button pButton pRipple type="button" label="Refresh" class="p-button-outlined p-button-info mr-2" (click)="refreshWithMessage()"></button>
    <button pButton pRipple type="button" label="Data records" class="p-button-outlined p-button-info mr-2" (click)="dataRecords()" [disabled]="selectedRecordsWithBACnetObjectCount() != 1 || selectedMappings.length != 1"></button>
  </div>

  <div class="p-toolbar-group-right">

  </div>
</p-toolbar>
<p-table [value]="mappings" [scrollable]="true" [(selection)]="selectedMappings" scrollHeight="flex" styleClass="p-datatable-sm">
  <ng-template pTemplate="header">
    <tr>
      <th rowspan="2" colspan="1" style="background-color: #92D050"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
      <th colspan="7" style="background-color: #92D050">BACnet<i pTooltip="BACnet object representing M-BUS meter" tooltipPosition="top" class="ml-1 pi pi-info-circle"></i></th>
      <th colspan="3" style="background-color: #ffc000">M-Bus request</th>
      <th colspan="5" style="background-color: #0070C0">M-Bus response<i pTooltip="Last response 2nd address" tooltipPosition="top" class="ml-1 pi pi-info-circle"></i></th>
    </tr>
    <tr>
      <th style="background-color: #92D050">Status</th>
      <th style="background-color: #92D050">Object</th>
      <th style="background-color: #92D050"><span pTooltip="When true - object name dynamically created based on meter data, automatic change" tooltipPosition="top" [escape]="false">D</span></th>
      <th style="background-color: #92D050">Name</th>
      <th style="background-color: #92D050">Description</th>
      <th style="background-color: #92D050">Location</th>
      <th style="background-color: #92D050"><div><span pTooltip="Number of meter data records mapped to BACnet objects" tooltipPosition="top">Data</span></div></th>
      <th style="background-color: #ffc000">Address</th>
      <th style="background-color: #ffc000">Baud Rate</th>
      <th style="background-color: #ffc000">Freq</th>
      <th style="background-color: #0070C0">ID</th>
      <th style="background-color: #0070C0"><span pTooltip="Meter manufacture" tooltipPosition="top">Man</span></th>
      <th style="background-color: #0070C0"><span pTooltip="Meter version" tooltipPosition="top">Ver</span></th>
      <th style="background-color: #0070C0">Medium</th>
      <th pTooltip="Connection test result" tooltipPosition="top" style="background-color: #0070C0">Test</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-mapping>
    <tr>
      <td>
        <p-tableCheckbox [value]="mapping"></p-tableCheckbox>
      </td>
      <td></td>
      <td *ngIf="mapping.bacnetObject?.identifier">{{mapping.bacnetObject.type}}{{mapping.bacnetObject.identifier}}</td>
      <td class="disabled-object" *ngIf="!mapping.bacnetObject">BV{{mapping.recordNumber}}</td>
      <td *ngIf="mapping.bacnetAutoName">V</td>
      <td *ngIf="!mapping.bacnetAutoName"></td>
      <td>{{mapping.bacnetObject?.name}}</td>
      <td>{{mapping.bacnetObject?.description}}</td>
      <td>{{mapping.bacnetObject?.location}}</td>
      <td></td>
      <td *ngIf="mapping.connAddress && mapping.connMethod == 'PRI'">{{mapping.connMethod}} {{mapping.connAddress}}</td>
      <td *ngIf="mapping.connAddress && mapping.connMethod == 'SEC'">{{mapping.connMethod}} {{(mapping.connAddress | number:'8.')?.replace(',','')?.replace(',','')}}</td>
      <td *ngIf="!mapping.connAddress">{{mapping.connAddress}}</td>
      <td>{{mapping.connBaudRate}}</td>
      <td>{{mapping.connReadInterval}}</td>
      <td>{{mapping.meterId}}</td>
      <td>{{mapping.meterManufacture}}</td>
      <td>{{mapping.meterVersion}}</td>
      <td>{{mapping.meterMedium}}</td>
      <td *ngIf="mapping.connAddress">{{mapping.status}}</td>
      <td *ngIf="!mapping.connAddress"></td>
    </tr>
  </ng-template>
</p-table>
<p-toast position="bottom-center"></p-toast>
<p-dialog [style]="{width: '50vw', height: '600px'}" header="Edit record" [(visible)]="editDialogVisible" [modal]="true">
  <form [formGroup]="editRecordForm" (ngSubmit)="onEditRecordSubmit()">
    <div class="formgrid grid mb-4">
      <div class="col-12 grid">
        <h3 style="color: #92D050">BACnet</h3>
      </div>
      <div class="grid bacnet-group-form">
        <div class="col-12 field-checkbox">
          <p-checkbox formControlName="autoName" [binary]="true" id="autoName" inputId="autoName"></p-checkbox>
          <label for="autoName">Dynamic name - based on the meter data</label>
        </div>
        <div class="col-12 field">
          <label for="name" class="block">Object name<span class="p-error">*</span></label>
          <input id="name" type="text" formControlName="name" pInputText />
          <small *ngIf="!editRecordForm?.get('name')?.valid && editRecordForm.get('name')?.enabled" id="username2-help" class="p-error block">Name is required</small>
        </div>
        <div class="col-12 field">
          <label for="description" class="block">Object description</label>
          <input id="description" type="text" formControlName="description" pInputText />
        </div>
        <div class="col-12 field">
          <label for="location" class="block">Object location</label>
          <input id="location" type="text" formControlName="location" pInputText />
        </div>
      </div>
      <!--        <h3 style="margin-left: 1.25rem">CONNECTION</h3>-->
      <div class="col-12 grid mt-2">
        <h3 style="color: #ffc000">M-Bus request</h3>
      </div>
      <div class="grid connection-group-form">
        <div class="col-12 field">
          <label for="address" class="block">Meter address</label>
          <div class="field-radiobutton">
            <p-radioButton name="method" value="PRI" formControlName="method" id="pri" inputId="pri"></p-radioButton>
            <label class="mr-2" for="pri">PRI</label>
            <p-radioButton name="method" value="SEC" formControlName="method" id="sec" inputId="sec"></p-radioButton>
            <label for="sec">SEC</label>
          </div>
          <!--            <p-inputMask id="address" formControlName="address" mask="99999999" [autoClear]=false></p-inputMask>-->
          <input id="address" type="text" formControlName="address" pInputText>
          <small *ngIf="!editRecordForm?.get('address')?.valid && editRecordForm.get('method')?.value == 'PRI'" id="invalidPRI" class="p-error block">Enter number in range 1-250</small>
          <small *ngIf="!editRecordForm?.get('address')?.valid && editRecordForm.get('method')?.value == 'SEC'" id="invalidSEC" class="p-error block">Enter value in format 99999999</small>
        </div>
        <div class="col-12 field">
          <label for="baudRate" class="block">Baud rate<span class="p-error">*</span></label>
          <p-dropdown id="baudRate" inputId="baudRate" [options]="baudRates" formControlName="baudRate">
            <ng-template let-baud pTemplate="selectedItem">
              <div>
                <div>{{baud}} bps</div>
              </div>
            </ng-template>
            <ng-template let-baud pTemplate="item">
              <div>
                <div>{{baud}} bps</div>
              </div>
            </ng-template>
          </p-dropdown>
          <!--            <p-inputNumber id="baudRate" formControlName="baudRate" [useGrouping]="false"></p-inputNumber>-->
          <small *ngIf="!editRecordForm?.get('baudRate')?.valid && editRecordForm.get('baudRate')?.touched" id="invalidBaudRare" class="p-error block">Baud rate is required if address is not empty</small>
        </div>
        <div class="col-12 field">
          <label for="interval" class="block">
            Frequency
            <span class="p-error">*</span>
            <i pTooltip="Number of requests daily" tooltipPosition="right" class="ml-1 pi pi-info-circle"></i>
          </label>
          <p-dropdown id="interval" inputId="interval" [options]="intervals" formControlName="interval">
            <ng-template let-freq pTemplate="selectedItem">
              <div>
                <div>{{freq}} ({{frequencyToInterval(freq)}})</div>
              </div>
            </ng-template>
            <ng-template let-freq pTemplate="item">
              <div>
                <div>{{freq}} daily</div>
              </div>
            </ng-template>
          </p-dropdown>
          <!--            <p-inputNumber id="interval" formControlName="interval" [useGrouping]="false"></p-inputNumber>-->
          <small *ngIf="!editRecordForm?.get('interval')?.valid && editRecordForm.get('interval')?.touched" id="invalidInterval" class="p-error block">Interval is required if address is not empty</small>
        </div>
      </div>
      <div class="col-12 grid mt-2 mb-8">
        <p-button [disabled]="!editRecordForm.valid" type="submit" class="mr-2" label="Save"></p-button>
        <p-button label="Cancel" (click)="hideEditDialog()"></p-button>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog header="Paste records" [(visible)]="pasteRecordsDialogVisible" [modal]="true">
  <form [formGroup]="pasteRecordsForm" (ngSubmit)="onPasteRecordsSubmit()">
    <div class="formgrid grid mt-3">
      <div class="col-12">
        <div class="grid">
          <div class="col-12 field">
            <label for="pasteDescription" class="block font-bold">Dynamic name</label>
            <div class="flex align-items-center justify-content-between">
              <label class="paste-label">{{copiedMapping?.bacnetAutoName ? 'V' : 'X'}}</label>
              <p-inputSwitch id="pasteAutoName" formControlName="autoName"></p-inputSwitch>
            </div>
          </div>
          <div class="col-12 field">
            <label for="pasteDescription" class="block font-bold">Description</label>
            <div class="flex align-items-center justify-content-between">
              <label class="paste-label">{{copiedMapping?.bacnetObject?.description}}</label>
              <p-inputSwitch id="pasteDescription" formControlName="description"></p-inputSwitch>
            </div>
          </div>
          <div class="col-12 field">
            <label for="pasteLocation" class="block font-bold">Location</label>
            <div class="flex align-items-center justify-content-between">
              <label class="paste-label">{{copiedMapping?.bacnetObject?.location}}</label>
              <p-inputSwitch id="pasteLocation" formControlName="location"></p-inputSwitch>
            </div>
          </div>
          <div class="col-12 field">
            <label for="pasteBaudrate" class="block font-bold">Baud rate</label>
            <div class="flex align-items-center justify-content-between">
              <label class="paste-label">{{copiedMapping?.connBaudRate}}</label>
              <p-inputSwitch id="pasteBaudrate" formControlName="baudRate"></p-inputSwitch>
            </div>
          </div>
          <div class="col-12 field">
            <label for="pasteInterval" class="block font-bold">Interval</label>
            <div class="flex align-items-center justify-content-between">
              <label class="paste-label">{{copiedMapping?.connReadInterval}}</label>
              <p-inputSwitch id="pasteInterval" formControlName="interval"></p-inputSwitch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-button [disabled]="!editRecordForm.valid" type="submit" class="mr-2" label="Save"></p-button>
    <p-button label="Cancel"></p-button>
  </form>
</p-dialog>
